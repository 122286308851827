/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { PaymentMethod } from '@stripe/stripe-js';
import { palette } from '../../lib/styles/palette';
import { selectBoxTheme } from '../../lib/styles/input/theme';

const Wrapper = styled.div<{ borderTopColor?: string }>`
  position: relative;
  width: 100%;
  height: 45px;
  z-index: 1;
  cursor: pointer;
  color: #000;
  font-size: 15px;
  text-align: left;
  line-height: 45px;
  ${(props) => props.theme};
  :before {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    margin-top: -1px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${({ borderTopColor }) =>
      borderTopColor
        ? `5px solid ${borderTopColor}`
        : `5px solid ${palette.logoColor}`};
  }
  :after {
    border-color: transparent transparent red transparent;
    top: 25%;
  }
  label {
    position: absolute;
    font-family: MyFont;
    top: 0px; /* 위치정렬 */
    left: 0px; /* 위치정렬 */
    width: 90%;
    height: 100%;
    padding: 0em 0.5em; /* select의 여백 크기 만큼 */
    z-index: -1;
    /* line-height: 45px; */
    color: #000;
    /* IE8에서 label이 위치한 곳이 클릭되지 않는 것 해결 */
    cursor: pointer;
  }
`;
const SelectContainer = styled.select`
  width: 100%;
  height: auto; /* 높이 초기화 */
  line-height: normal; /* line-height 초기화 */
  font-family: MyFont; /* 폰트 상속 */
  padding: 0.8em 0.5em; /* 여백과 높이 결정 */
  opacity: 0; /* 숨기기 */
  filter: alpha(opacity=0); /* IE8 숨기기 */
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: 15px;
`;
const Option = styled.option`
  color: #000;
`;
type SelectBoxProps = {
  id?: string;
  name: string;
  value: string;
  options: {
    value: string;
    label: string;
  }[];
  cardOptions?: PaymentMethod[];
  onChange: React.ChangeEventHandler;
  borderTopColor?: string;
  style?: React.CSSProperties;
  selectContainerStyle?: React.StyleHTMLAttributes<any>;
  labelStyle?: React.CSSProperties;
  // labelStyle: { [key: string]: React.CSSProperties };
  placeholder?: string;
  theme?: 'outline' | undefined;
};

const SelectBox: React.FC<SelectBoxProps> = ({
  id,
  name,
  value,
  options,
  cardOptions,
  onChange,
  borderTopColor,
  style,
  selectContainerStyle,
  labelStyle,
  placeholder,
  theme,
}) => (
  <Wrapper
    style={style}
    borderTopColor={borderTopColor}
    theme={theme ? selectBoxTheme[theme] : selectBoxTheme.default}
  >
    <label
      style={{
        ...labelStyle,
      }}
    >
      {!value ? placeholder : value}
    </label>
    <SelectContainer
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      style={selectContainerStyle}
    >
      <option hidden value="" />
      {options &&
        options.length > 0 &&
        options.map((data) => (
          <Option key={`${data.label}`} value={data.value}>
            {data.label}
          </Option>
        ))}
      {cardOptions &&
        cardOptions.length > 0 &&
        cardOptions.map((data: PaymentMethod) => (
          <Option key={data.id} value={data.card?.last4}>
            {data.card?.last4}
          </Option>
        ))}
    </SelectContainer>
  </Wrapper>
);

export default SelectBox;
