export const palette = {
  grayColors: [
    '#f8f9fa', // 0
    '#f1f3f5', // 1
    '#e9ecef', // 2
    '#dee2e6', // 3
    '#ced4da', // 4
    '#adb5bd', // 5
    '#868e96', // 6
    '#495057', // 7
    '#343a40', // 8
    '#212529', // 9
    '#192226', // 10
    '#C3C5C7', // 11
    '#11171a', // 12
    '#666', // 13
    '#747474', // 14
  ],
  cyanColors: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    '#0c8599',
    '#0b7285',
  ],
  logoColor: '#7361d4',
  grey: '#c1c1c1',
  black: '#242424',
  logoColors: [
    '#514494',
    '#7361d4',
    '#8f81dd',
    '#aba0e5',
    '#c7c0ee',
    '#e3dff6',
    '#f1effb',
  ],
};

export const headerTextColor = '#7361d4';
export const textColor = '#212529';

export const mainColor = '#8f81dd';
export const hoverColor = '#f1effb';
export const mainBackgroundColor = '#f7f7fe';

export const successTextColor = '#1d8127';
export const successBoxColor = '#f1f6f2';

export const warningTextColor = '#d41f1b';
export const warningBoxColor = '#fcf0f1';

export const codeArea = '#343a40';
export const codeBlock = '#212529';

export const grayTextColor = '#686868';
export const grayBorderColor = '#e5e4f4';

export const getMethodColor = 'rgb(29, 129, 39)';
export const postMethodColor = '#186faf';
export const deleteMethodColor = '#D41f1c';
export const orangeTextColor = '#f08d49';
