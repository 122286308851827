import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';
import {
  UserAction,
  checkUser,
  checkUserSuccess,
  checkUserFailure,
  logout,
  changeUserInfo,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  changeUserField,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePasswordSuccess,
  verifyChangePassword,
  changePassword,
  changePasswordFailure,
  exitUser,
  exitUserSuccess,
  exitUserFailure,
  setSessionExpired,
  initializeUserStatus,
} from './actions';
import { UserState } from './types';
import { AsyncStatus } from '../types';
import {
  getUser,
  getApiUser,
  setUser,
  removeUser,
  removeApiUser,
  setApiUser,
} from '../../lib/utils/storage';

const initialState: UserState = {
  data: getUser(),
  apiUser: getApiUser(),
  login: {
    status: AsyncStatus.INIT,
    error: '',
  },
  logout: {
    status: AsyncStatus.INIT,
  },
  checkUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  getCurrentUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  changeUserInfo: {
    status: AsyncStatus.INIT,
    error: '',
  },
  verifyChangePassword: {
    status: AsyncStatus.INIT,
    error: '',
  },
  changePassword: {
    status: AsyncStatus.INIT,
    error: '',
  },
  exitUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  sessionExpired: false,
};

const reducer = createReducer<UserState, UserAction>(initialState)
  .handleAction(logout, (state) =>
    produce(state, (draft) => {
      draft.logout.status = AsyncStatus.SUCCESS;
      removeApiUser();
      removeUser();
    }),
  )
  .handleAction(checkUser, (state) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(checkUserSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.SUCCESS;
      draft.data = payload;
      setUser(payload);
    }),
  )
  .handleAction(checkUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.FAILURE;
      draft.checkUser.error = payload;
      draft.data = null;
      draft.apiUser = null;
      removeUser();
      removeApiUser();
    }),
  )
  .handleAction(getCurrentUser, (state) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getCurrentUserSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.SUCCESS;
      draft.apiUser = payload;
      setApiUser(payload);
    }),
  )
  .handleAction(getCurrentUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.FAILURE;
      draft.getCurrentUser.error = payload;
      draft.data = null;
      draft.apiUser = null;
      removeApiUser();
      removeUser();
    }),
  )
  .handleAction(changeUserInfo, (state) =>
    produce(state, (draft) => {
      draft.changeUserInfo.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(changeUserInfoSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.data = payload;
      draft.changeUserInfo.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(changeUserInfoFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.changeUserInfo.status = AsyncStatus.FAILURE;
      draft.changeUserInfo.error = payload;
    }),
  )
  .handleAction(changeUserField, (state, { payload }) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    }),
  )
  .handleAction(verifyChangePassword, (state) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(verifyChangePasswordSuccess, (state) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(verifyChangePasswordFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.FAILURE;
      draft.verifyChangePassword.error = payload;
    }),
  )
  .handleAction(changePassword, (state) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(changePasswordSuccess, (state) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(changePasswordFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.FAILURE;
      draft.changePassword.error = payload;
    }),
  )
  .handleAction(exitUser, (state) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(exitUserSuccess, (state) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(exitUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.FAILURE;
      draft.exitUser.error = payload;
    }),
  )
  .handleAction(setSessionExpired, (state, { payload }) =>
    produce(state, (draft) => {
      draft.sessionExpired = payload;
    }),
  )
  // initial state 를 주기에는, Logout 할 때 Thunk 에서 Local Storage 를 지우는 시점과 안 맞을 수 있어서 null 을 준다.
  .handleAction(initializeUserStatus, () => ({
    ...initialState,
    data: null,
    apiUser: null,
  }));
export default reducer;
