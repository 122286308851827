import React, { useEffect, useMemo } from 'react';
import { useHistory, Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'react-moment';
import { useUser, useKey, useBilling, useModal } from '../../../hooks';
import { Button } from '../../button';
import { zIndexs } from '../../../lib/styles/theme';
import { palette } from '../../../lib/styles/palette';
import logoIcon from '../../../images/logo_single.png';
import { DropDown } from '../../input';
import { UserButton } from '..';
import { AsyncStatus } from '../../../modules/types';
import { redirectToAuthPage } from '../../../lib/utils/http';

const Container = styled.div`
  width: 234px;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  background-color: #f7f5ff;
  text-align: left;
  z-index: ${zIndexs.sidebar};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;
const LogoContainer = styled.div`
  height: 140px;
  padding: 52px 0 0 29px;
`;
const LogoIcon = styled.img`
  width: 43px;
  height: auto;
`;
const PageMenuContainer = styled.div``;
const DropDownContainer = styled.div`
  margin-bottom: 1rem;
`;
const MenuContainer = styled(NavLink)<{ disabled?: boolean }>`
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px 0 29px;
  color: ${(props) => (props.disabled ? '#c1c1c1' : '#000')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  span {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.3px;
  }
  div {
    position: absolute;
    display: none;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: ${palette.logoColor};
  }
  &.active {
    div {
      display: block;
    }
  }
  &.sub_page {
    margin-bottom: 0;
    span {
      color: ${palette.logoColor};
      padding-left: 15px;
    }
  }
  :hover {
    background-color: #7361d409;
  }
`;

const SearchBar = styled.input`
  margin: 10px 10px 30px 10px;
  width: 190px;
  height: 30px;
`;

const ExternalLinkMenu = styled.div`
  /* height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px 0 29px; */

  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px 0 29px;

  div {
    position: absolute;
    display: none;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: ${palette.logoColor};
  }
  &.active {
    div {
      display: block;
    }
  }
  a {
    color: ${palette.logoColor};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.3px;
  }

  &.sub_page {
    margin-bottom: 0;
    a {
      color: ${palette.logoColor};
      padding-left: 15px;
    }
  }
  :hover {
    background-color: #7361d409;
  }
`;
const BottomMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding-left: 29px;
  padding-bottom: 30px;
`;
const FreeTrialInfoContainer = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  span {
    margin: 0 0 8px 2px;
    font-size: 12px;
  }
`;
const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 43px;
  margin: 0 10px;
  padding: 3px 0;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.26px;
  overflow: hidden;
  span {
    display: inline-block;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  div {
    color: ${palette.logoColor};
    cursor: pointer;
  }
`;

export function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const { user, handleLogout, handleInitializeUserStatus } = useUser();
  const { getFreeTrialInfo, handleInitializeKeyStatus } = useKey();
  const { handleInitializeBillingStatus } = useBilling();
  const { handleInitializeModalStatus } = useModal();

  useEffect(() => {
    if (user.logout.status === AsyncStatus.SUCCESS) {
      handleInitializeUserStatus();
      handleInitializeKeyStatus();
      handleInitializeBillingStatus();
      handleInitializeModalStatus();
    }
  }, [
    user.logout.status,
    handleInitializeUserStatus,
    handleInitializeKeyStatus,
    handleInitializeBillingStatus,
    handleInitializeModalStatus,
  ]);

  /**
   * Upgrade 버튼을 클릭하면 호출되는 함수
   */
  const handleClickUpgrade = () => {
    history.push('/account/order');
  };

  const handleLogoutClick = () => {
    handleLogout();
    redirectToAuthPage();
  };

  const trialInfo = useMemo(() => {
    const info = getFreeTrialInfo();
    if (!info) {
      return null;
    }
    return info;
  }, [getFreeTrialInfo]);

  return (
    <Container>
      <LogoContainer>
        <Link to="/">
          <LogoIcon src={logoIcon} alt="logo" />
        </Link>
      </LogoContainer>
      <PageMenuContainer>
        {/* DASHBOARD */}
        <DropDownContainer>
          <MenuContainer exact to="/">
            <div />
            <span>DASHBOARD</span>
          </MenuContainer>
        </DropDownContainer>
        {/* BILLING */}
        <DropDownContainer>
          <DropDown
            theme="sidebar"
            title="BILLING"
            className="test-sidebar-billing"
            open
          >
            <MenuContainer
              to="/billing/overview"
              className="sub_page test-sidebar-billing-overview"
            >
              <div />
              <span>Overview</span>
            </MenuContainer>
            <MenuContainer
              to="/billing/paymentInfo"
              className="sub_page"
              isActive={() =>
                location.pathname.includes('paymentInfo') ||
                location.pathname.includes('card')
              }
            >
              <div />
              <span>Payment Info</span>
            </MenuContainer>
          </DropDown>
        </DropDownContainer>
        {/* DOCUMENTATION */}
        <DropDownContainer>
          <DropDown
            theme="sidebar"
            title="DOCUMENTATION"
            className="test-sidebar-documentation"
            open
          >
            <MenuContainer to="/document/api" className="sub_page">
              <div />
              <span>API</span>
            </MenuContainer>
            <MenuContainer to="/document/skins" className="sub_page">
              <div />
              <span>Voiceskins List</span>
            </MenuContainer>
          </DropDown>
        </DropDownContainer>
        {/* ACCOUNT */}
        <DropDownContainer>
          <DropDown
            theme="sidebar"
            title="ACCOUNT"
            className="test-sidebar-account"
            open
          >
            <MenuContainer to="/account/myInfo" className="sub_page">
              <div />
              <span>My Info</span>
            </MenuContainer>
            <MenuContainer
              to="/account/apiKeys"
              className="sub_page test-sidebar-account-apikeys"
            >
              <div />
              <span>API Keys</span>
            </MenuContainer>
            <MenuContainer to="/account/privacy" className="sub_page">
              <div />
              <span>Terms & Policy</span>
            </MenuContainer>
          </DropDown>
        </DropDownContainer>
      </PageMenuContainer>
      <BottomMenuContainer>
        {user.data && (
          <>
            <FreeTrialInfoContainer>
              {trialInfo && (
                <>
                  <span>
                    Free trial ends on{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      <Moment unix format="MMM DD YYYY">
                        {trialInfo.trialEndsAt
                          ? trialInfo.trialEndsAt
                          : undefined}
                      </Moment>
                    </span>
                  </span>
                  <span>
                    You currently have left{' '}
                    <span style={{ fontWeight: 'bold' }}>{`${
                      100 - trialInfo.apiData.success_call_count
                    } calls`}</span>
                  </span>
                  <Button
                    inverted
                    type="button"
                    onClick={handleClickUpgrade}
                    style={{
                      width: '176px',
                      marginTop: '12px',
                      borderRadius: '20px',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: 'MyFont',
                      textAlign: 'center',
                    }}
                    className="test-upgrade-button"
                  >
                    <span role="img" aria-label="upgrade">
                      🚀
                    </span>{' '}
                    Upgrade
                  </Button>
                </>
              )}
            </FreeTrialInfoContainer>
            <UserInfoContainer>
              <UserButton />
              <UserDetailContainer>
                <span>{user.data.email}</span>
                <div onClick={handleLogoutClick}>Log out</div>
              </UserDetailContainer>
            </UserInfoContainer>
          </>
        )}
      </BottomMenuContainer>
    </Container>
  );
}

export const DocsSidebar = () => {
  const handleLoginClick = () => {
    redirectToAuthPage();
  };

  return (
    <Container>
      <LogoContainer onClick={handleLoginClick} style={{ cursor: 'pointer' }}>
        <LogoIcon src={logoIcon} alt="logo" />
      </LogoContainer>
      <PageMenuContainer>
        {/* DOCUMENTATION */}
        <DropDownContainer>
          <DropDown
            theme="sidebar"
            title="DOCUMENTATION"
            className="test-sidebar-documentation"
            open
          >
            <MenuContainer to="/document/api" className="sub_page">
              <div />
              <span>API</span>
            </MenuContainer>
            <MenuContainer to="/document/skins" className="sub_page">
              <div />
              <span>Voiceskins List</span>
            </MenuContainer>
          </DropDown>
        </DropDownContainer>
      </PageMenuContainer>
      <BottomMenuContainer>
        <UserInfoContainer>
          <UserButton />
          <UserDetailContainer>
            <div onClick={handleLoginClick}>Log in</div>
          </UserDetailContainer>
        </UserInfoContainer>
      </BottomMenuContainer>
    </Container>
  );
};
