import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  getPaymentMethods,
  addPaymentMethod,
  changeBillField,
  createSubscription,
  getAllInvoices,
  initializeBillingStaus,
  getPriceMetadata,
} from '../modules/billing/actions';
import { RootState } from '../modules/rootReducer';

export default function useBilling() {
  const dispatch = useDispatch();
  const billing = useSelector((state: RootState) => state.billing);

  const handleGetPaymentMethods = useCallback(
    () => dispatch(getPaymentMethods()),
    [dispatch],
  );
  const handleAddPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod) => dispatch(addPaymentMethod(paymentMethod)),
    [dispatch],
  );
  const handleChangeBillField = useCallback(
    (key: string, value: any) => dispatch(changeBillField(key, value)),
    [dispatch],
  );
  const handleCreateSubscription = useCallback(
    (
      keyName: string,
      priceId: string,
      paymentMethodId?: string,
      action?: 'upgrade',
      subscriptionId?: string,
    ) =>
      dispatch(
        createSubscription(
          keyName,
          priceId,
          paymentMethodId,
          action,
          subscriptionId,
        ),
      ),
    [dispatch],
  );
  const handleGetAllInvoices = useCallback(() => dispatch(getAllInvoices()), [
    dispatch,
  ]);

  const handleInitializeBillingStatus = useCallback(
    () => dispatch(initializeBillingStaus()),
    [dispatch],
  );

  const handleGetPriceMetadata = useCallback(
    () => dispatch(getPriceMetadata()),
    [dispatch],
  );
  return {
    billing,
    handleGetPaymentMethods,
    handleAddPaymentMethod,
    handleChangeBillField,
    handleCreateSubscription,
    handleGetAllInvoices,
    handleInitializeBillingStatus,
    handleGetPriceMetadata,
  };
}
