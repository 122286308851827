import gatewayClient from './gatewayClient';
import apiClient from './apiClient';

export const getCurrentUserApi = () => apiClient.get('/api/user/current');

export const checkUserApi = () => gatewayClient.get('/user/check');

export const logoutApi = () => gatewayClient.post('/user/logout');

export const changeUserInfoApi = (name: string, phone: string, job: string) =>
  gatewayClient.put('/user/info', { name, phone, job });

export const verifyChangePasswordApi = (password: string) =>
  gatewayClient.post('/user/password/verify', { password });

export const changePasswordApi = (password: string) =>
  gatewayClient.put('/user/password', { password });

export const exitUserApi = (exitReason: string) =>
  gatewayClient.put('/user/exit', { exitReason });
