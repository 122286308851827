export enum ModalTypes {
  DEFAULT = 'DEFAULT',
  REGISTER_AGREE = 'REGISTER_AGREE',
  CONDITIONAL = 'CONDITIONAL',
}

export type ModalState = {
  DEFAULT: {
    show: boolean;
    message: string | undefined;
  };
  CONDITIONAL: {
    show: boolean;
    message: string | undefined;
  };
  REGISTER_AGREE: {
    show: boolean;
    message: string | undefined;
  };
  [type: string]: {
    show: boolean;
    message: string | undefined;
  };
};
