import React from 'react';
import styled from 'styled-components';
import { theme } from '../../lib/styles/button/theme';

const Container = styled.button`
  width: 100%;
  height: 100%;
  padding: 0.25rem 1rem;

  font-family: MyFont;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;

  outline: none;
  border: none;

  ${(props) => props.theme};
`;
export type ButtonProps = {
  name?: string;
  /** 버튼 안의 내용 */
  children: React.ReactNode;
  /** 클릭하면 호출되는 함수 */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** 버튼의 type  */
  type?: 'button' | 'submit' | 'reset' | undefined;
  /** 버튼의 disable 여부 */
  disabled?: boolean;
  /** 버튼의 inverted 여부 */
  inverted?: boolean;
  /** style object */
  style?: any;
  /** class 이름 */
  className?: string;
};

function Button({
  name,
  children,
  onClick,
  type,
  disabled,
  inverted,
  style,
  className,
}: ButtonProps) {
  return (
    <Container
      name={name}
      className={className}
      onClick={onClick}
      type={type}
      disabled={disabled}
      theme={
        disabled ? theme.disabled : inverted ? theme.inverted : theme.default
      }
      style={style}
    >
      {children}
    </Container>
  );
}

export default Button;
