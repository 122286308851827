import React from 'react';
import PhoneInput from 'react-phone-number-input';
import CustomCountry from './CustomCountry';
import '../css/custom_country_style.css';
import '../css/signup_style.css';

function PhoneInputComponent({ phone, onPhoneChange }) {
  return (
    <PhoneInput
      international={false}
      limitMaxLength
      country="US"
      placeholder="MOBILE"
      value={phone}
      onChange={onPhoneChange}
      countrySelectComponent={CustomCountry}
      className="custom-phone-class"
      style={{ display: 'flex' }}
    />
  );
}

export default PhoneInputComponent;
