/**
 * Action Types
 */
export const LOGIN = 'user/LOGIN';

export const LOGOUT = 'user/LOGOUT';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'user/LOGOUT_FAILURE';

export const CHECK_USER = 'user/CHECK_USER';
export const CHECK_USER_SUCCESS = 'user/CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'user/CHECK_USER_FAILURE';

export const GET_CURRENT_USER = 'user/GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'user/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'user/GET_CURRENT_USER_FAILURE';

export const CHANGE_USER_INFO = 'user/CHANGE_USER_INFO';
export const CHANGE_USER_INFO_SUCCESS = 'user/CHANGE_USER_INFO_SUCCESS';
export const CHANGE_USER_INFO_FAILURE = 'user/CHANGE_USER_INFO_FAILURE';

export const VERIFY_CHANGE_PASSWORD = 'user/VERIFY_CHANGE_PASSWORD';
export const VERIFY_CHANGE_PASSWORD_SUCCESS =
  'user/VERIFY_CHANGE_PASSWORD_SUCCESS';
export const VERIFY_CHANGE_PASSWORD_FAILURE =
  'user/VERIFY_CHANGE_PASSWORD_FAILURE';

export const CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'user/CHANGE_PASSWORD_FAILURE';

export const EXIT_USER = 'user/EXIT_USER';
export const EXIT_USER_SUCCESS = 'user/EXIT_USER_SUCCESS';
export const EXIT_USER_FAILURE = 'user/EXIT_USER_FAILURE';

// export const DELETE_KEY = "user/DELETE_KEY";
// export const DELETE_KEY_SUCCESS = "user/DELETE_KEY_SUCCESS";
// export const DELETE_KEY_FAILURE = "user/DELETE_KEY_FAILURE";

export const CHANGE_FIELD = 'user/CHANGE_FIELD';

export const SET_SESSION_EXPIRED = 'user/SET_SESSION_EXPIRED';
export const INITIALIZE_USER_STATUS = 'user/INITIALIZE_USER_STATUS';
