import { css } from 'styled-components';
import { palette } from '../palette';

export const textBoxTheme = {
  default: css`
    font-size: 15px;
    border-bottom: 1px solid ${palette.logoColor};
  `,
  outline: css`
    border: 2px solid ${palette.logoColor};
    padding: 10px 10px;
  `,
};

export const dropdownTheme = {
  default: {},
  sidebar: {
    header: css`
      padding: 6px 20px 6px 29px;
      cursor: pointer;
      :hover {
        background-color: #7361d409;
      }
    `,
    title: css`
      font-size: 15px;
      font-weight: bold;
    `,
  },
  disabled: {
    header: css`
      padding: 6px 20px 6px 29px;
      cursor: auto;
    `,
    title: css`
      color: #c1c1c1;
      font-size: 15px;
      font-weight: bold;
    `,
  },
};

export const selectBoxTheme = {
  default: css`
    border-bottom: 1px solid ${palette.logoColor};
  `,
  outline: css`
    border: 2px solid ${palette.logoColor};
  `,
};
