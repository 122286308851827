import { createAction, ActionType } from 'typesafe-actions';
import { INITIALIZE_MODAL_STATUS, SET_MODAL } from './contants';
import { ModalTypes } from './types';

export const setModal = createAction(
  SET_MODAL,
  (type: ModalTypes, show: boolean, message?: string) => ({
    type,
    show,
    message,
  }),
)();

export const initializeModalStatus = createAction(INITIALIZE_MODAL_STATUS)();

const actions = {
  setModal,
  initializeModalStatus,
};

export type ModalAction = ActionType<typeof actions>;
