import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKeys,
  generateKey,
  changeKeyField,
  cancelSubscription,
  reactivateSubscription,
  initializeKeyStaus,
  getKey,
  createWhiteList,
  deleteWhiteList,
  createUsageAlarmRule,
  deleteUsageAlarmRule,
  editUsageAlarmRule,
} from '../modules/key/actions';
import { RootState } from '../modules/rootReducer';

export default function useKey() {
  const dispatch = useDispatch();
  const key = useSelector((state: RootState) => state.key);

  const handleGetKeys = useCallback(() => dispatch(getKeys()), [dispatch]);
  const handleGenerateAPIKey = useCallback(
    (name: string) => dispatch(generateKey(name)),
    [dispatch],
  );
  const handleChangeKeyField = useCallback(
    (_key: string, value: any) => dispatch(changeKeyField(_key, value)),
    [dispatch],
  );
  const handleCancelSubscription = useCallback(
    (keyId: string) => dispatch(cancelSubscription(keyId)),
    [dispatch],
  );
  const handleReactivateSubscription = useCallback(
    (keyId: string) => dispatch(reactivateSubscription(keyId)),
    [dispatch],
  );

  const getFreeTrialInfo = useCallback(() => {
    const [trialKey] = key.keys.filter((_key) => _key.is_trial);
    if (!trialKey) {
      return null;
    }
    return {
      keyId: trialKey.id,
      subscriptionId: trialKey.subscription_id,
      trialEndsAt: trialKey.trial_ends_at,
      apiData: trialKey.api_data,
    };
  }, [key.keys]);

  const handleInitializeKeyStatus = useCallback(
    () => dispatch(initializeKeyStaus()),
    [dispatch],
  );

  const handleGetKey = useCallback(
    (keyId: string) => {
      dispatch(getKey(keyId));
    },
    [dispatch],
  );

  const handleCreateWhiteList = useCallback(
    (keyId: string, origins: string[]) => {
      dispatch(createWhiteList(keyId, origins));
    },
    [dispatch],
  );

  const handleDeleteWhiteList = useCallback(
    (keyId: string, origins: string[]) => {
      dispatch(deleteWhiteList(keyId, origins));
    },
    [dispatch],
  );

  const handleCreateUsageAlarm = useCallback(
    (
      keyId: string,
      type: 'minutes' | 'hours' | 'days' | 'months',
      duration: number,
      max: number,
    ) => {
      dispatch(createUsageAlarmRule(keyId, type, duration, max));
    },
    [dispatch],
  );

  const handleDeleteUsageAlarm = useCallback(
    (keyId: string, ruleIds: string[]) => {
      dispatch(deleteUsageAlarmRule(keyId, ruleIds));
    },
    [dispatch],
  );

  const handleEditUsageAlarm = useCallback(
    (keyId: string, ruleId: string, max: number, isEnabled?: boolean) => {
      dispatch(editUsageAlarmRule(keyId, ruleId, max, isEnabled));
    },
    [dispatch],
  );

  return {
    key,
    getFreeTrialInfo,
    handleGetKeys,
    handleGenerateAPIKey,
    handleChangeKeyField,
    handleCancelSubscription,
    handleReactivateSubscription,
    handleInitializeKeyStatus,
    handleGetKey,
    handleCreateWhiteList,
    handleDeleteWhiteList,
    handleCreateUsageAlarm,
    handleDeleteUsageAlarm,
    handleEditUsageAlarm,
  };
}
