import React from 'react';
import styled from 'styled-components';
import { palette } from '../../../lib/styles/palette';
import { useUser } from '../../../hooks';

const Container = styled.div`
  width: 44px;
  height: 44px;
  line-height: 44px;
  background-color: ${palette.logoColor};
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #fff;
`;

export default function UserButton() {
  const { user } = useUser();

  if (!user.data) return null;

  return (
    <Container>
      {user.data.name?.trim().charAt(0).toUpperCase() ||
        user.data.email?.[0].toUpperCase() ||
        'L'}
    </Container>
  );
}
