import { take, put, call } from 'redux-saga/effects';
import {
  CHECK_USER,
  CHANGE_USER_INFO,
  GET_CURRENT_USER,
  VERIFY_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  EXIT_USER,
  LOGOUT,
} from './constants';
import {
  checkUserSuccess,
  checkUserFailure,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePasswordFailure,
  changePasswordSuccess,
  exitUserSuccess,
  exitUserFailure,
} from './actions';
import {
  checkUserApi,
  getCurrentUserApi,
  changeUserInfoApi,
  verifyChangePasswordApi,
  changePasswordApi,
  exitUserApi,
  logoutApi,
} from '../../api/user';
import { Response } from '../../api/apiClient';
import { removeUser, removeApiUser } from '../../lib/utils/storage';
import { errorMessage } from '../../api/constants';

export function* handleCheckUser() {
  while (true) {
    try {
      yield take(CHECK_USER);
      const res: Response = yield call(checkUserApi);
      if (res.success) {
        yield put(checkUserSuccess(res.data));
      } else {
        yield put(checkUserFailure(res.error));
      }
    } catch (e) {
      removeUser();
      removeApiUser();
      yield put(checkUserFailure(errorMessage));
    }
  }
}

export function* handleLogout() {
  while (true) {
    try {
      yield take(LOGOUT);
      yield call(logoutApi);
    } catch (e) {
      console.error(e);
    }
  }
}

export function* handleGetCurrentUser() {
  while (true) {
    try {
      yield take(GET_CURRENT_USER);
      const res: Response = yield call(getCurrentUserApi);
      if (res.success) {
        yield put(getCurrentUserSuccess(res.data));
      } else {
        yield put(getCurrentUserFailure(res.error));
      }
    } catch (e) {
      removeApiUser();
      removeUser();
      yield put(checkUserFailure(errorMessage));
    }
  }
}

export function* handleChangeUserInfo() {
  while (true) {
    try {
      const {
        payload: { name, phone, job },
      } = yield take(CHANGE_USER_INFO);
      const res: Response = yield call(changeUserInfoApi, name, phone, job);
      if (res.success) {
        yield put(changeUserInfoSuccess(res.data));
      } else {
        yield put(changeUserInfoFailure(res.error));
      }
    } catch (e) {
      yield put(changeUserInfoFailure(errorMessage));
    }
  }
}

export function* handleVerifyChangePassword() {
  while (true) {
    try {
      const {
        payload: { password },
      } = yield take(VERIFY_CHANGE_PASSWORD);
      const res: Response = yield call(verifyChangePasswordApi, password);
      if (res.success) {
        yield put(verifyChangePasswordSuccess());
      } else {
        yield put(verifyChangePasswordFailure(res.error));
      }
    } catch (e) {
      yield put(verifyChangePasswordFailure(errorMessage));
    }
  }
}

export function* handleChangePassword() {
  while (true) {
    try {
      const {
        payload: { password },
      } = yield take(CHANGE_PASSWORD);
      const res: Response = yield call(changePasswordApi, password);
      if (res.success) {
        yield put(changePasswordSuccess());
      } else {
        yield put(changePasswordFailure(res.error));
      }
    } catch (e) {
      yield put(changePasswordFailure(errorMessage));
    }
  }
}

export function* handleExitUser() {
  while (true) {
    try {
      const {
        payload: { exitReason },
      } = yield take(EXIT_USER);
      const res: Response = yield call(exitUserApi, exitReason);
      if (res.success) {
        yield put(exitUserSuccess());
      } else {
        yield put(exitUserFailure(res.error));
      }
    } catch (e) {
      yield put(exitUserFailure(errorMessage));
    }
  }
}
