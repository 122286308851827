import React, { useMemo, useState, useCallback } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useModal, useUser, useKey } from '../../../hooks';
import { Modal } from '../../modal';
import { TextBox, SelectBox } from '../../input';
import { Button } from '../../button';
import { palette } from '../../../lib/styles/palette';
import { ModalTypes } from '../../../modules/modal/types';
import { countries } from '../../../config';

const Container = styled.div`
  width: 100%;
`;
const CardForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const CardLabel = styled.label`
  font-size: 1rem;
  letter-spacing: 0.3px;
  color: ${palette.grayColors[8]};
  text-align: left;
`;
const MainTextBoxContainer = styled.div`
  width: 348px;
  margin: 6px 0px 20px 0px;
`;
const SubTextBoxContainer = styled(MainTextBoxContainer)`
  display: flex;
  /* jsutify-content: space-between; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`;
const CardNumberElementContainer = styled(MainTextBoxContainer)`
  border-bottom: 1px solid ${palette.logoColor};
  padding: 10px 10px;
`;
const SubCardElementContainer = styled(MainTextBoxContainer)`
  width: 165px;
  margin: 19px 15px 20px 0px;
  ${(props) =>
    props.country &&
    css`
      margin: 3px 15px 20px 0px;
    `}
`;
const CVCElementContainer = styled(SubCardElementContainer)`
  border-bottom: 1px solid ${palette.logoColor};
  padding: 10px 10px;
`;
const ButtonContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 247px;
  height: 50px;
`;

/**
 * card element style option
 */
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: 'black',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: 'grey',
          },
        },
        invalid: {
          color: 'rgb(255, 90, 87)',
        },
        complete: {
          color: palette.logoColor,
        },
      },
    }),
    [],
  );
  return options;
};

export default function PaymentMethodForm({
  onSubmit,
  setIsCreating,
  keyName,
  selectedCard,
}) {
  const { modal, handleSetModal } = useModal();
  const { register, watch } = useForm();
  const { user } = useUser();
  const { key } = useKey();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [country, setCountry] = useState(undefined);

  const createPaymentMethod = async (
    name,
    address_line1,
    address_line2,
    address_city,
    address_zip,
    address_country,
    address_state,
  ) => {
    setIsCreating(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: address_city,
          country: address_country,
          line1: address_line1,
          line2: address_line2,
          postal_code: address_zip,
          state: address_state,
        },
        name,
      },
    });
    setIsCreating(false);
    // error 가 발생하면 모달을 띄운다
    if (error) {
      handleSetModal(ModalTypes.DEFAULT, true, error.message);
      return false;
    }
    // props 으로 받은 onSubmit 함수에 paymentMethod 를 전달한다
    return paymentMethod;
  };

  /**
   * 카드 정보 입력을 마치고 submit 하는 버튼을 클릭하면 호출되는 함수
   * @param {Event} e
   */
  const handleSubmit = async (e) => {
    // refresh 방지
    e.preventDefault();
    // user data 가 존재하지 않으면 return
    if (!user.data) {
      return;
    }
    // Stripe.js 가 load 되지 않으면 submit 을 시키지 않는다
    if (!stripe || !elements) {
      return;
    }
    const name = watch('name');
    const address_line1 = watch('address_line1');
    const address_line2 = watch('address_line2');
    const address_city = watch('address_city');
    const address_state = watch('address_state');
    const address_country = country;
    const address_zip = watch('address_zip');

    // name 이 존재하지 않으면 error 를 띄운다
    if (
      !keyName ||
      !keyName.trim() ||
      key.keys.some((key) => key.name.trim() === keyName.trim())
    ) {
      handleSetModal(
        ModalTypes.DEFAULT,
        true,
        'Please enter a unique key name.',
      );
      return;
    }
    // 새로운 카드를 입력받는다면 필드가 타당한지 체크한다
    if (selectedCard === 'another') {
      if (!name.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'Card name is required.');
        return;
      }
      // address_line1 이 비어있으면 return
      if (!address_line1.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'Address line 1 is required.');
        return;
      }
      // address_line2 는 optional
      // address_city 이 비어있으면 return
      if (!address_city.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'City is required.');
        return;
      }
      // address_zip 이 비어있으면 return
      if (!address_zip.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'Zip code is required.');
        return;
      }
      // address_country 이 비어있으면 return
      if (!address_country.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'Country is required.');
        return;
      }
      // address_state 이 비어있으면 return
      if (!address_state.trim()) {
        handleSetModal(ModalTypes.DEFAULT, true, 'State is required.');
        return;
      }
      // payment method 를 create 한다
      const paymentMethod = await createPaymentMethod(
        name,
        address_line1,
        address_line2.trim(),
        address_city,
        address_zip,
        address_country,
        address_state,
      );
      // paymentMethod 가 false 이면 return
      if (!paymentMethod) {
        return;
      }
      // props 으로 받은 onSubmit 함수에 paymentMethod 를 전달한다
      onSubmit(paymentMethod.id);
    }
    // 그게 아니라 기존 카드로 구매하는 것이면 바로 submit 을 호출한다
    else {
      onSubmit();
    }
  };

  /**
   * Country 를 변경하면 호출되는 함수
   */
  const onChangeCountry = useCallback(
    (e) => {
      const countryName = e.target.value;
      setCountry(countryName);
    },
    [setCountry],
  );

  return (
    <Container>
      <CardForm onSubmit={handleSubmit}>
        {selectedCard === 'another' && (
          <>
            {/* name */}
            <CardLabel>*Name on card</CardLabel>
            <MainTextBoxContainer>
              <TextBox
                style={{ paddingLeft: '0.5rem' }}
                name="name"
                ref={register}
              />
            </MainTextBoxContainer>
            {/* card number */}
            <CardLabel>*Card number</CardLabel>
            <CardNumberElementContainer>
              <CardNumberElement options={options} />
            </CardNumberElementContainer>
            <SubTextBoxContainer>
              {/* expiration date */}
              <CardLabel>
                *Expiration date
                <CVCElementContainer>
                  <CardExpiryElement options={options} />
                </CVCElementContainer>
              </CardLabel>
              {/* cvc */}
              <CardLabel>
                *CVC
                <CVCElementContainer>
                  <CardCvcElement options={options} />
                </CVCElementContainer>
              </CardLabel>
            </SubTextBoxContainer>
            {/* Address Line 1 */}
            <CardLabel>*Address Line 1</CardLabel>
            <MainTextBoxContainer>
              <TextBox
                style={{ paddingLeft: '0.5rem' }}
                name="address_line1"
                ref={register}
              />
            </MainTextBoxContainer>
            {/* Address Line 2 */}
            <CardLabel>Address Line 2</CardLabel>
            <MainTextBoxContainer>
              <TextBox
                style={{ paddingLeft: '0.5rem' }}
                name="address_line2"
                ref={register}
              />
            </MainTextBoxContainer>
            <SubTextBoxContainer>
              {/* City */}
              <CardLabel>
                *City
                <SubCardElementContainer>
                  <TextBox
                    style={{ paddingLeft: '0.5rem' }}
                    name="address_city"
                    ref={register}
                  />
                </SubCardElementContainer>
              </CardLabel>
              {/* ZIP */}
              <CardLabel>
                *ZIP
                <SubCardElementContainer>
                  <TextBox
                    style={{ paddingLeft: '0.5rem' }}
                    name="address_zip"
                    ref={register}
                  />
                </SubCardElementContainer>
              </CardLabel>
            </SubTextBoxContainer>
            <SubTextBoxContainer>
              {/* Country */}
              <CardLabel>
                *Country
                <SubCardElementContainer country>
                  <SelectBox
                    name="address_country"
                    value={country}
                    onChange={onChangeCountry}
                    options={countries}
                    style={{}}
                    selectContainerStyle={{
                      height: '30px',
                      paddingBottom: '20px',
                    }}
                    labelStyle={{
                      top: '6px',
                    }}
                  />
                </SubCardElementContainer>
              </CardLabel>
              {/* State */}
              <CardLabel>
                *State
                <SubCardElementContainer>
                  <TextBox
                    style={{ paddingLeft: '0.5rem' }}
                    name="address_state"
                    ref={register}
                  />
                </SubCardElementContainer>
              </CardLabel>
            </SubTextBoxContainer>
          </>
        )}
        <ButtonContainer>
          <Button type="submit" disabled={!stripe}>
            Pay
          </Button>
        </ButtonContainer>
      </CardForm>
      {/* modal */}
      <Modal
        visible={modal[ModalTypes.DEFAULT].show}
        onOK={() => handleSetModal(ModalTypes.DEFAULT, false, '')}
        message={modal[ModalTypes.DEFAULT].message}
      />
    </Container>
  );
}
