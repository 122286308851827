import { User, ApiUser } from '../../modules/user/types';
import { KeyDataState } from '../../modules/key/types';

export const removeUser = () => localStorage.removeItem('user');

export const getUser = (): User | null => {
  try {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : user;
  } catch (err) {
    removeUser();
    return null;
  }
};

export const setUser = (user: User) =>
  localStorage.setItem('user', JSON.stringify(user));

export const removeApiUser = () => localStorage.removeItem('apiUser');

export const getApiUser = (): ApiUser | null => {
  try {
    const apiUser = localStorage.getItem('apiUser');
    return apiUser ? JSON.parse(apiUser) : apiUser;
  } catch (err) {
    removeApiUser();
    return null;
  }
};

export const setApiUser = (apiUser: ApiUser) =>
  localStorage.setItem('apiUser', JSON.stringify(apiUser));

export const removeKeys = () => localStorage.removeItem('keys');

export const getKeys = (): KeyDataState[] | null => {
  try {
    const keys = localStorage.getItem('keys');
    return keys ? JSON.parse(keys) : keys;
  } catch (err) {
    removeKeys();
    return null;
  }
};

export const setKeys = (keys: KeyDataState) =>
  localStorage.setItem('keys', JSON.stringify(keys));
