import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import loadable from '@loadable/component';
import { Sidebar } from './components/common';
import { NewBannerWithLogo } from './components/event/NewBannerWithLogo';
import PrivateRouter from './pages/PrivateRouter';
import { useUser, useKey } from './hooks';
import { AsyncStatus } from './modules/types';
import './App.css';
import { redirectToAuthPage, apiUrl, skinsUrl } from './lib/utils/http';

const DashboardPage = loadable(() => import('./pages/DashboardPage'));
const AccountRouter = loadable(() => import('./pages/account/AccountRouter'));
const BillingRouter = loadable(() => import('./pages/billing/BillingRouter'));
const DocumentRouter = loadable(() =>
  import('./pages/documentation/DocumentRouter'),
);

const App: React.FC = () => {
  const { user, handleCheckUser, handleGetCurrentUser } = useUser();
  const { handleGetKeys } = useKey();

  /**
   * check user's token validation.
   */
  useEffect((): any => {
    handleCheckUser();
  }, [handleCheckUser]);

  /**
   * if user checking is success, get api user.
   */
  useEffect(() => {
    if (user.checkUser.status === AsyncStatus.SUCCESS) {
      handleGetCurrentUser();
    }
    if (user.checkUser.status === AsyncStatus.FAILURE) {
      if (
        window.location.href !== apiUrl &&
        window.location.href !== skinsUrl
      ) {
        redirectToAuthPage();
      }
    }
  }, [user.checkUser.status, handleGetCurrentUser]);

  /**
   * free trial 여부를 key state 로 판단하기 때문에 처음부터 key 를 가져온다
   */
  useEffect(() => {
    if (user.data) {
      handleGetKeys();
    }
  }, [user.data, handleGetKeys]);

  return (
    <div
      className="App"
      style={{
        minWidth: isMobile ? '100vw' : '1400px',
        paddingLeft: '234px',
      }}
    >
      {user.data ? <Sidebar /> : null}
      {user.data && user.data.email !== 'oliver@storie.co' && (
        <NewBannerWithLogo />
      )}
      <Switch>
        <PrivateRouter exact path="/">
          <DashboardPage />
        </PrivateRouter>
        <PrivateRouter path="/billing">
          <BillingRouter />
        </PrivateRouter>
        <PrivateRouter path="/account">
          <AccountRouter />
        </PrivateRouter>
        <Route path="/document">
          <DocumentRouter />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
