import { PaymentMethod } from '@stripe/stripe-js';
import client from './apiClient';

export const getPaymentMethodsApi = () => client.get(`/api/billing/cards`);

export const addPaymentMethodApi = (paymentMethod: PaymentMethod) =>
  client.post(`/api/billing/card`, { paymentMethodId: paymentMethod.id });

export const createSubscriptionApi = (
  keyName: string,
  priceId: string,
  paymentMethodId?: string,
  action?: 'upgrade',
  subscriptionId?: string,
) =>
  client.post(`/api/billing/subscription`, {
    keyName,
    priceId,
    paymentMethodId,
    action,
    subscriptionId,
  });

export const getAllInvoicesApi = () => client.get(`/api/billing/all_invoices`);

export const getPriceMetadataApi = () =>
  client.get('/api/billing/price_metadata');
