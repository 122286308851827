import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../hooks';
import logoImage from '../../../images/logo.png';

const HeaderBlock = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  width: 100%;
  background: white;
  padding-right: 2rem;
  padding-left: 2rem;
  z-index: 30;
`;

const Wrapper = styled.div`
  padding: 20px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .right {
    position: relative;
    display: flex;
    align-items: center;
  }
`;
const LogoContainer = styled.div`
  cursor: pointer;
`;
const LogoImageContainer = styled.img`
  width: 6.5rem;
  height: auto;
  display: block;
  margin: auto;
`;

function Header() {
  const { user } = useUser();
  const history = useHistory();
  return (
    <>
      <HeaderBlock>
        <Wrapper>
          <LogoContainer
            onClick={() =>
              user.data ? history.push('/account/apiKeys') : undefined
            }
          >
            <LogoImageContainer src={logoImage} alt="logo image" />
          </LogoContainer>
        </Wrapper>
      </HeaderBlock>
    </>
  );
}

export default Header;
