/* eslint-disable consistent-return */
/* eslint-disable no-irregular-whitespace */
import { useCallback, useMemo } from 'react';

export default function useCodeExample() {
  const codeExampleLanguages = useMemo(
    () => ['curl', 'javascript', 'python'],
    [],
  );
  const url = process.env.REACT_APP_API_SERVER_URL;

  const handleGetCodeExample = useCallback(
    (lang: string, apiKey: string) => {
      if (!apiKey) {
        return `
      Your API Key is required.`;
      }
      if (lang === 'curl') {
        return `
      curl --location --request POST '${url}/v1/conversion' \\
      --header 'apiKey: ${apiKey}' \\
      --header 'Content-Type: application/json' \\
      --data-raw '{
        "text": "hello world! my name is Martha Sage",
        "speaker_id": "Martha Sage",
        "emphasis": "[0, 5]",
        "speed": 1,
        "pause": 0,
        "encoding": "mp3"
      }' \\
      --output sample.mp3`;
      }
      if (lang === 'javascript') {
        return `
      const url = "${url}/v1/conversion";
      const data = {
        text: "hello world! my name is Martha Sage",
        speaker_id: "Martha Sage",
        emphasis: "[0, 5]",
        speed: 1,
        pause: 0,
        encoding: "mp3"
      };
      const option = {
        method: "POST",
        headers: {
          apiKey: "${apiKey}",   // Your API key goes here
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(data),
      };

      fetch(url, option)
        .then((res) => res.arrayBuffer())
        .then((buffer) => {
          // you can also download the audio file
          const blob = new Blob([buffer], { type: "audio/mpeg" });
          const objUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objUrl;
          a.download = "sample.mp3";
          // create click event handler to revoke objectURL and remove this event handler
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              this.removeEventListener("click", clickHandler);
            }, 100);
          };
          // add above event handler and click to download
          a.addEventListener("click", clickHandler);
          a.click();
          console.log("Audio content written to file " + filename)
        })
        .catch((error) => console.log(error));
      `;
      }
      if (lang === 'python') {
        return `
      import requests
      import json
      ​
      url = '${url}/v1/conversion'
      data = json.dumps({
          "text": "hello world! my name is Martha Sage",
          "speaker_id": "Martha Sage",
          "emphasis": '[0, 5]',
          "speed": 1,
          "pause": 0,
          "encoding": "mp3"
      })
      headers = {
          'apiKey': '${apiKey}',   # Your API key goes here
          'Content-Type': 'application/json; charset=utf-8'
      }
      res = requests.post(url, headers=headers, data=data)

      # you can also download the audio file
      outfile = 'sample.mp3'
      with open(outfile, 'wb') as f:
          f.write(res.content)
      ​
      print(f'Audio content written to file "{outfile}"')`;
      }
    },
    [url],
  );

  return {
    codeExampleLanguages,
    handleGetCodeExample,
  };
}
