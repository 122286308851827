export const GET_KEYS = 'key/GET_KEYS';
export const GET_KEYS_SUCCESS = 'key/GET_KEYS_SUCCESS';
export const GET_KEYS_FAILURE = 'key/GET_KEYS_FAILURE';

export const GENERATE_KEY = 'key/GENERATE_KEY';
export const GENERATE_KEY_SUCCESS = 'key/GENERATE_KEY_SUCCESS';
export const GENERATE_KEY_FAILURE = 'key/GENERATE_KEY_FAILURE';

export const CHANGE_KEY_FIELD = 'key/CHANGE_KEY_FIELD';

export const CANCEL_SUBSCRIPTION = 'key/CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'key/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'key/CANCEL_SUBSCRIPTION_FAILURE';

export const REACTIVATE_SUBSCRIPTION = 'key/REACTIVATE_SUBSCRIPTION';
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  'key/REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAILURE =
  'key/REACTIVATE_SUBSCRIPTION_FAILURE';

export const INITIALIZE_KEY_STATUS = 'key/INITIALIZE_KEY_STATUS';

// lovo-api-whitelist
export const GET_KEY = 'key/GET_KEY';
export const GET_KEY_SUCCESS = 'key/GET_KEY_SUCCESS';
export const GET_KEY_FAILURE = 'key/GET_KEY_FAILURE';

export const CREATE_WHITE_LIST = 'key/CREATE_WHITE_LIST';
export const CREATE_WHITE_LIST_SUCCESS = 'key/CREATE_WHITE_LIST_SUCCESS';
export const CREATE_WHITE_LIST_FAILURE = 'key/CREATE_WHITE_LIST_FAILURE';

export const DELETE_WHITE_LIST = 'key/DELETE_WHITE_LIST';
export const DELETE_WHITE_LIST_SUCCESS = 'key/DELETE_WHITE_LIST_SUCCESS';
export const DELETE_WHITE_LIST_FAILURE = 'key/DELETE_WHITE_LIST_FAILURE';

export const CREATE_USAGE_ALARM_RULE = 'key/CREATE_USAGE_ALARM_RULE';
export const CREATE_USAGE_ALARM_RULE_SUCCESS =
  'key/CREATE_USAGE_ALARM_RULE_SUCCESS';
export const CREATE_USAGE_ALARM_RULE_FAILURE =
  'key/CREATE_USAGE_ALARM_RULE_FAILURE';

export const DELETE_USAGE_ALARM_RULE = 'key/DELETE_USAGE_ALARM_RULE';
export const DELETE_USAGE_ALARM_RULE_SUCCESS =
  'key/DELETE_USAGE_ALARM_RULE_SUCCESS';
export const DELETE_USAGE_ALARM_RULE_FAILURE =
  'key/DELETE_USAGE_ALARM_RULE_FAILURE';

export const EDIT_USAGE_ALARM_RULE = 'key/EDIT_USAGE_ALARM_RULE';
export const EDIT_USAGE_ALARM_RULE_SUCCESS =
  'key/EDIT_USAGE_ALARM_RULE_SUCCESS';
export const EDIT_USAGE_ALARM_RULE_FAILURE =
  'key/EDIT_USAGE_ALARM_RULE_FAILURE';
