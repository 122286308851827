import { createReducer } from 'typesafe-actions';
import { produce } from 'immer';
import {
  KeyAction,
  getKeys,
  getKeysFailure,
  getKeysSuccess,
  generateKey,
  generateKeyFailure,
  generateKeySuccess,
  changeKeyField,
  cancelSubscription,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  reactivateSubscription,
  reactivateSubscriptionFailure,
  reactivateSubscriptionSuccess,
  initializeKeyStaus,
  getKey,
  getKeySuccess,
  getKeyFailure,
  createWhiteList,
  createWhiteListSuccess,
  createWhiteListFailure,
  deleteWhiteList,
  deleteWhiteListSuccess,
  deleteWhiteListFailure,
  createUsageAlarmRule,
  createUsageAlarmRuleSuccess,
  createUsageAlarmRuleFailure,
  deleteUsageAlarmRule,
  deleteUsageAlarmRuleSuccess,
  deleteUsageAlarmRuleFailure,
  editUsageAlarmRule,
  editUsageAlarmRuleSuccess,
  editUsageAlarmRuleFailure,
} from './actions';
import { AsyncStatus } from '../types';
import { KeyState } from './types';
import { getKeys as getStorageKey } from '../../lib/utils/storage';

const initialState: KeyState = {
  keys: getStorageKey() || [],
  selectedKey: [],
  getKeys: {
    status: AsyncStatus.INIT,
    error: '',
  },
  generateKey: {
    status: AsyncStatus.INIT,
    error: '',
  },
  cancelSubscription: {
    status: AsyncStatus.INIT,
    error: '',
  },
  reactivateSubscription: {
    status: AsyncStatus.INIT,
    error: '',
  },
  getKey: {
    status: AsyncStatus.INIT,
    error: '',
  },
  createWhiteList: {
    status: AsyncStatus.INIT,
    error: '',
  },
  deleteWhiteList: {
    status: AsyncStatus.INIT,
    error: '',
  },
  createUsageAlarm: {
    status: AsyncStatus.INIT,
    error: '',
  },
  deleteUsageAlarm: {
    status: AsyncStatus.INIT,
    error: '',
  },
  editUsageAlarm: {
    status: AsyncStatus.INIT,
    error: '',
  },
};

const keyReducer = createReducer<KeyState, KeyAction>(initialState)
  .handleAction(generateKey, (state) =>
    produce(state, (draft) => {
      draft.generateKey.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(generateKeySuccess, (state) =>
    produce(state, (draft) => {
      draft.generateKey.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(generateKeyFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.generateKey.status = AsyncStatus.FAILURE;
      draft.generateKey.error = payload;
    }),
  )
  .handleAction(getKeys, (state) =>
    produce(state, (draft) => {
      draft.getKeys.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getKeysSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.keys = payload;
      draft.getKeys.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(getKeysFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getKeys.status = AsyncStatus.FAILURE;
      draft.getKeys.error = payload;
    }),
  )
  .handleAction(changeKeyField, (state, { payload }) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    }),
  )
  .handleAction(cancelSubscription, (state) =>
    produce(state, (draft) => {
      draft.cancelSubscription.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(cancelSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cancelSubscription.status = AsyncStatus.SUCCESS;
      draft.keys = state.keys.map((key) =>
        key.id === payload.id ? payload : key,
      );
    }),
  )
  .handleAction(cancelSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cancelSubscription.status = AsyncStatus.FAILURE;
      draft.cancelSubscription.error = payload;
    }),
  )
  .handleAction(reactivateSubscription, (state) =>
    produce(state, (draft) => {
      draft.reactivateSubscription.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(reactivateSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.reactivateSubscription.status = AsyncStatus.SUCCESS;
      draft.keys = state.keys.map((key) =>
        key.id === payload.id ? payload : key,
      );
    }),
  )
  .handleAction(reactivateSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.reactivateSubscription.status = AsyncStatus.FAILURE;
      draft.reactivateSubscription.error = payload;
    }),
  )
  .handleAction(initializeKeyStaus, () => initialState)

  .handleAction(getKey, (state) =>
    produce(state, (draft) => {
      draft.getKey.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getKeySuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getKey.status = AsyncStatus.SUCCESS;
      draft.selectedKey = payload;
    }),
  )
  .handleAction(getKeyFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getKey.status = AsyncStatus.FAILURE;
      draft.getKey.error = payload;
    }),
  )

  .handleAction(createWhiteList, (state) =>
    produce(state, (draft) => {
      draft.createWhiteList.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(createWhiteListSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createWhiteList.status = AsyncStatus.SUCCESS;
      draft.selectedKey.allowedOrigins = payload;
    }),
  )
  .handleAction(createWhiteListFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createWhiteList.status = AsyncStatus.FAILURE;
      draft.createWhiteList.error = payload;
    }),
  )

  .handleAction(deleteWhiteList, (state) =>
    produce(state, (draft) => {
      draft.deleteWhiteList.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(deleteWhiteListSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.deleteWhiteList.status = AsyncStatus.SUCCESS;
      draft.selectedKey.allowedOrigins = payload;
    }),
  )
  .handleAction(deleteWhiteListFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.deleteWhiteList.status = AsyncStatus.FAILURE;
      draft.deleteWhiteList.error = payload;
    }),
  )

  .handleAction(createUsageAlarmRule, (state) =>
    produce(state, (draft) => {
      draft.createUsageAlarm.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(createUsageAlarmRuleSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createUsageAlarm.status = AsyncStatus.SUCCESS;
      draft.selectedKey.rules = payload;
    }),
  )
  .handleAction(createUsageAlarmRuleFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createUsageAlarm.status = AsyncStatus.FAILURE;
      draft.createUsageAlarm.error = payload;
    }),
  )

  .handleAction(deleteUsageAlarmRule, (state) =>
    produce(state, (draft) => {
      draft.deleteUsageAlarm.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(deleteUsageAlarmRuleSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.deleteUsageAlarm.status = AsyncStatus.SUCCESS;
      draft.selectedKey.rules = draft.selectedKey.rules.filter(
        (rule: any) => !payload.includes(rule._id),
      );
    }),
  )
  .handleAction(deleteUsageAlarmRuleFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.deleteUsageAlarm.status = AsyncStatus.FAILURE;
      draft.deleteUsageAlarm.error = payload;
    }),
  )

  .handleAction(editUsageAlarmRule, (state) =>
    produce(state, (draft) => {
      draft.editUsageAlarm.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(editUsageAlarmRuleSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      const { _id, max } = payload[0];
      draft.editUsageAlarm.status = AsyncStatus.SUCCESS;
      draft.selectedKey.rules.find((rule: any) => rule._id === _id).max = max;
    }),
  )
  .handleAction(editUsageAlarmRuleFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.editUsageAlarm.status = AsyncStatus.FAILURE;
      draft.editUsageAlarm.error = payload;
    }),
  );

export default keyReducer;
