import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './rootSaga';
import { rootReducer } from './rootReducer';
import sagaMiddleware from './middlewares';

// redux compose enhancer 를 생성한다
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

// store 를 생성한다
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

// saga middleware 를 run 시킨다
sagaMiddleware.run(rootSaga);

export default store;
