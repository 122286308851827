import { take, put, call } from 'redux-saga/effects';
import {
  GENERATE_KEY,
  GET_KEYS,
  CANCEL_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION,
  GET_KEY,
  CREATE_WHITE_LIST,
  DELETE_WHITE_LIST,
  CREATE_USAGE_ALARM_RULE,
  DELETE_USAGE_ALARM_RULE,
  EDIT_USAGE_ALARM_RULE,
} from './constants';
import {
  generateKeyFailure,
  generateKeySuccess,
  getKeysFailure,
  getKeysSuccess,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFailure,
  getKeySuccess,
  getKeyFailure,
  createWhiteListSuccess,
  createWhiteListFailure,
  deleteWhiteListSuccess,
  deleteWhiteListFailure,
  createUsageAlarmRuleSuccess,
  createUsageAlarmRuleFailure,
  deleteUsageAlarmRuleSuccess,
  deleteUsageAlarmRuleFailure,
  editUsageAlarmRuleSuccess,
  editUsageAlarmRuleFailure,
} from './actions';
import { parseKeyData } from './utils';
import { Response } from '../../api/apiClient';
import {
  generateAPIKeyApi,
  getKeysApi,
  cancelSubscriptionApi,
  reactivateSubscriptionApi,
  getKeyApi,
  createWhiteListOriginsApi,
  deleteWhiteListOriginsApi,
  createUsageAlarmRuleApi,
  deleteUsageAlarmRuleApi,
  editUsageAlarmRuleApi,
} from '../../api/keys';
import { setKeys, removeKeys } from '../../lib/utils/storage';
import { errorMessage } from '../../api/constants';

export function* handleGetKeys() {
  while (true) {
    try {
      yield take(GET_KEYS);
      const res: Response = yield call(getKeysApi);
      if (res.success) {
        const parsedKeys = res.data.map((key: any) => parseKeyData(key));
        setKeys(parsedKeys);
        yield put(getKeysSuccess(parsedKeys));
      } else {
        removeKeys();
        yield put(getKeysFailure(res.error));
      }
    } catch (e) {
      removeKeys();
      yield put(getKeysFailure(errorMessage));
    }
  }
}
export function* handleGenerateAPIKey() {
  while (true) {
    try {
      const {
        payload: { name },
      } = yield take(GENERATE_KEY);
      const res: Response = yield call(generateAPIKeyApi, name);
      if (res.success) {
        yield put(generateKeySuccess());
      } else {
        yield put(generateKeyFailure(res.error));
      }
    } catch (e) {
      yield put(generateKeyFailure(errorMessage));
    }
  }
}

/** CANCEL_SUBSCRIPTION action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleCancelSubscription() {
  while (true) {
    try {
      // payload 는 keyId 이다
      const { payload } = yield take(CANCEL_SUBSCRIPTION);
      const res: Response = yield call(cancelSubscriptionApi, payload);
      if (res.success) {
        yield put(cancelSubscriptionSuccess(parseKeyData(res.data)));
      } else {
        yield put(cancelSubscriptionFailure(res.error));
      }
    } catch (e) {
      yield put(cancelSubscriptionFailure(errorMessage));
    }
  }
}

/** REACTIVATE_SUBSCRIPTION action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleReactivateSubscription() {
  while (true) {
    try {
      // payload 는 keyId 이다
      const { payload } = yield take(REACTIVATE_SUBSCRIPTION);
      const res: Response = yield call(reactivateSubscriptionApi, payload);
      if (res.success) {
        yield put(reactivateSubscriptionSuccess(parseKeyData(res.data)));
      } else {
        yield put(reactivateSubscriptionFailure(res.error));
      }
    } catch (e) {
      yield put(reactivateSubscriptionFailure(errorMessage));
    }
  }
}

// get key
export function* handleGetKey() {
  while (true) {
    try {
      // payload는 keyId이다.
      const { payload } = yield take(GET_KEY);
      const res: Response = yield call(getKeyApi, payload);
      if (res.success) {
        yield put(getKeySuccess(parseKeyData(res.data)));
      } else {
        yield put(getKeyFailure(errorMessage));
      }
    } catch (e) {
      yield put(getKeyFailure(errorMessage));
    }
  }
}

// edit whitelist
export function* handleCreateWhiteList() {
  while (true) {
    try {
      // payload 는 keyId, origins
      const { payload } = yield take(CREATE_WHITE_LIST);

      const res: Response = yield call(
        createWhiteListOriginsApi,
        payload.keyId,
        payload.origins,
      );
      if (res.success) {
        // yield put(editWhiteListSuccess(parseKeyData(res.data)));
        yield put(createWhiteListSuccess(res.data));
      } else {
        yield put(createWhiteListFailure(errorMessage));
      }
    } catch (e) {
      yield put(createWhiteListFailure(errorMessage));
    }
  }
}

// delete whitelist
export function* handleDeleteWhiteList() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_WHITE_LIST);
      const res: Response = yield call(
        deleteWhiteListOriginsApi,
        payload.keyId,
        payload.origins,
      );
      if (res.success) {
        yield put(deleteWhiteListSuccess(res.data));
      } else {
        yield put(deleteWhiteListFailure(errorMessage));
      }
    } catch (e) {
      yield put(deleteWhiteListFailure(errorMessage));
    }
  }
}

export function* handleCreateUsageAlarm() {
  while (true) {
    try {
      const { payload } = yield take(CREATE_USAGE_ALARM_RULE);
      const res: Response = yield call(
        createUsageAlarmRuleApi,
        payload.keyId,
        payload.type,
        payload.duration,
        payload.max,
      );
      if (res.success) {
        yield put(createUsageAlarmRuleSuccess(res.data));
      } else {
        yield put(createUsageAlarmRuleFailure(res.error));
      }
    } catch (e) {
      yield put(createUsageAlarmRuleFailure(errorMessage));
    }
  }
}

export function* handleDeleteUsageAlarm() {
  while (true) {
    try {
      const { payload } = yield take(DELETE_USAGE_ALARM_RULE);
      const res: Response = yield call(
        deleteUsageAlarmRuleApi,
        payload.keyId,
        payload.ruleIds,
      );
      if (res.success) {
        yield put(deleteUsageAlarmRuleSuccess(res.data));
      } else {
        yield put(deleteUsageAlarmRuleFailure(errorMessage));
      }
    } catch (e) {
      yield put(deleteUsageAlarmRuleFailure(errorMessage));
    }
  }
}

export function* handleEditUsageAlarm() {
  while (true) {
    try {
      const { payload } = yield take(EDIT_USAGE_ALARM_RULE);
      const res: Response = yield call(
        editUsageAlarmRuleApi,
        payload.keyId,
        payload.ruleId,
        payload.max,
        payload.isEnabled,
      );
      if (res.success) {
        yield put(editUsageAlarmRuleSuccess(res.data));
      } else {
        yield put(editUsageAlarmRuleFailure(errorMessage));
      }
    } catch (e) {
      yield put(editUsageAlarmRuleFailure(errorMessage));
    }
  }
}
