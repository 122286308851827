import React, { useMemo } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useUser } from '../hooks';

const PrivateRouter: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useUser();
  const isAuthenticated = useMemo(() => user.data, [user.data]);

  if (!isAuthenticated) {
    return null;
  }
  return <Route {...rest} render={() => children} />;
};

export default PrivateRouter;
