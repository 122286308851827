import ReactGA from 'react-ga';

import { take, put, call } from 'redux-saga/effects';
import {
  GET_PAYMENT_METHODS,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_ALL_INVOICES,
  GET_PRICE_METADATA,
} from './constants';
import {
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  addPaymentMethodFailure,
  addPaymentMethodSuccess,
  createSubscriptionFailure,
  createSubscriptionSuccess,
  getAllInvoicesFailure,
  getAllInvoicesSuccess,
  getPriceMetadataSuccess,
  getPriceMetadataFailure,
} from './actions';
import {
  getPaymentMethodsApi,
  addPaymentMethodApi,
  createSubscriptionApi,
  getAllInvoicesApi,
  getPriceMetadataApi,
} from '../../api/billing';
import { Response } from '../../api/apiClient';
import { changeUserField } from '../user/actions';
import { errorMessage } from '../../api/constants';

/** GET_CARDS action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleGetPaymentMethods() {
  while (true) {
    try {
      yield take(GET_PAYMENT_METHODS);
      const res: Response = yield call(getPaymentMethodsApi);
      if (res.success) {
        yield put(getPaymentMethodsSuccess(res.data));
      } else {
        yield put(getPaymentMethodsFailure(res.error));
      }
    } catch (e) {
      yield put(getPaymentMethodsFailure(errorMessage));
    }
  }
}

/** ADD_PAYMENT_METHOD action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleAddPaymentMethod() {
  while (true) {
    try {
      // payload 는 payment method 이다
      const { payload } = yield take(ADD_PAYMENT_METHOD);
      const res: Response = yield call(addPaymentMethodApi, payload);
      if (res.success) {
        yield put(addPaymentMethodSuccess(res.data));
      } else {
        yield put(addPaymentMethodFailure(res.error));
      }
    } catch (e) {
      yield put(addPaymentMethodFailure(errorMessage));
    }
  }
}

/** CREATE_SUBSCRIPTION action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleCreateSubscription() {
  while (true) {
    try {
      // payload 는 payment method 이다
      const {
        payload: { keyName, priceId, paymentMethodId, action, subscriptionId },
      } = yield take(CREATE_SUBSCRIPTION);
      const res: Response = yield call(
        createSubscriptionApi,
        keyName,
        priceId,
        paymentMethodId,
        action,
        subscriptionId,
      );
      if (res.success) {
        ReactGA.event({
          category: 'API',
          action: 'Subscribe',
          label: 'Success',
        });
        yield put(createSubscriptionSuccess(res.data));
      } else {
        yield put(createSubscriptionFailure(res.error));
      }
    } catch (e) {
      yield put(createSubscriptionFailure(errorMessage));
    }
  }
}

/** user data 를 반환하는 action 이 dispatch 되면 업데이트 시키는 saga */
export function* handleUpdateUser() {
  while (true) {
    try {
      // payload 는 user data 이다
      const { payload } = yield take([
        ADD_PAYMENT_METHOD_SUCCESS,
        CREATE_SUBSCRIPTION_SUCCESS,
      ]);
      yield put(changeUserField('apiData', payload));
    } catch (e) {
      console.log(e);
    }
  }
}

/** GET_ALL_INVOICES action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleGetAllInvoices() {
  while (true) {
    try {
      yield take(GET_ALL_INVOICES);
      const res: Response = yield call(getAllInvoicesApi);
      if (res.success) {
        yield put(getAllInvoicesSuccess(res.data));
      } else {
        yield put(getAllInvoicesFailure(res.error));
      }
    } catch (e) {
      yield put(getAllInvoicesFailure(errorMessage));
    }
  }
}

/** GET_PRICE_METADATA action 이 dispatch 되면 api 를 호출하는 saga */
export function* handleGetPriceMetadata() {
  while (true) {
    try {
      yield take(GET_PRICE_METADATA);
      const res: Response = yield call(getPriceMetadataApi);
      if (res.success) {
        yield put(getPriceMetadataSuccess(res.data));
      } else {
        yield put(getPriceMetadataFailure(res.error));
      }
    } catch (e) {
      yield put(getPriceMetadataFailure(errorMessage));
    }
  }
}
