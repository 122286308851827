import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import {
  BillingActions,
  getPaymentMethods,
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  addPaymentMethod,
  addPaymentMethodFailure,
  addPaymentMethodSuccess,
  changeBillField,
  createSubscription,
  createSubscriptionFailure,
  createSubscriptionSuccess,
  getAllInvoices,
  getAllInvoicesFailure,
  getAllInvoicesSuccess,
  initializeBillingStaus,
  getPriceMetadata,
  getPriceMetadataSuccess,
  getPriceMetadataFailure,
} from './actions';
import { BillingState } from './types';
import { AsyncStatus } from '../types';

const initialState: BillingState = {
  paymentMethods: [],
  selectedCard: null,
  invoice: {},
  getPaymentMethods: {
    status: AsyncStatus.INIT,
    error: '',
  },
  addPaymentMethod: {
    status: AsyncStatus.INIT,
    error: '',
  },
  createSubscription: {
    status: AsyncStatus.INIT,
    error: '',
  },
  getAllInvoices: {
    status: AsyncStatus.INIT,
    error: '',
  },
  getPriceMetadata: {
    status: AsyncStatus.INIT,
    error: '',
  },
};

const reducer = createReducer<BillingState, BillingActions>(initialState)
  .handleAction(getPaymentMethods, (state) =>
    produce(state, (draft) => {
      draft.getPaymentMethods.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getPaymentMethodsSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getPaymentMethods.status = AsyncStatus.SUCCESS;
      draft.paymentMethods = payload;
    }),
  )
  .handleAction(getPaymentMethodsFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getPaymentMethods.status = AsyncStatus.FAILURE;
      draft.getPaymentMethods.error = payload;
    }),
  )
  .handleAction(addPaymentMethod, (state) =>
    produce(state, (draft) => {
      draft.addPaymentMethod.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(addPaymentMethodSuccess, (state) =>
    produce(state, (draft) => {
      draft.addPaymentMethod.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(addPaymentMethodFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.addPaymentMethod.status = AsyncStatus.FAILURE;
      draft.addPaymentMethod.error = payload;
    }),
  )
  .handleAction(changeBillField, (state, { payload }) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    }),
  )
  .handleAction(createSubscription, (state) =>
    produce(state, (draft) => {
      draft.createSubscription.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(createSubscriptionSuccess, (state) =>
    produce(state, (draft) => {
      draft.createSubscription.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(createSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createSubscription.status = AsyncStatus.FAILURE;
      draft.createSubscription.error = payload;
    }),
  )
  .handleAction(getAllInvoices, (state) =>
    produce(state, (draft) => {
      draft.getAllInvoices.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getAllInvoicesSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getAllInvoices.status = AsyncStatus.SUCCESS;
      draft.invoice = payload;
    }),
  )
  .handleAction(getAllInvoicesFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getAllInvoices.status = AsyncStatus.FAILURE;
      draft.getAllInvoices.error = payload;
    }),
  )
  .handleAction(initializeBillingStaus, () => initialState)
  .handleAction(getPriceMetadata, (state) =>
    produce(state, (draft) => {
      draft.getPriceMetadata.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getPriceMetadataSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getPriceMetadata.status = AsyncStatus.SUCCESS;
      draft.priceMetadata = payload;
    }),
  )
  .handleAction(getPriceMetadataFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getPriceMetadata.status = AsyncStatus.FAILURE;
      draft.getPriceMetadata.error = payload;
    }),
  );

export default reducer;
