import client from './apiClient';

export const getKeysApi = () => client.get(`/api/keys`);

export const generateAPIKeyApi = (name: string) =>
  client.post(`/api/keys`, { name });

export const cancelSubscriptionApi = (keyId: string) =>
  client.delete(`/api/keys?keyId=${keyId}`);

export const reactivateSubscriptionApi = (keyId: string) =>
  client.put(`/api/keys?keyId=${keyId}`);

export const getKeyApi = (keyId: string) => client.get(`api/keys/${keyId}`);

export const createWhiteListOriginsApi = (keyId: string, origins: string[]) =>
  client.post(`/api/keys/${keyId}/whitelist`, { origins });

export const deleteWhiteListOriginsApi = (keyId: string, origins: string[]) =>
  client.delete(`api/keys/${keyId}/whitelist`, { data: { origins } });

export const createUsageAlarmRuleApi = (
  keyId: string,
  type: 'minutes' | 'hours' | 'days' | 'months',
  duration: number,
  max: number,
) => client.post(`api/policies/${keyId}`, { type, duration, max });

export const deleteUsageAlarmRuleApi = (keyId: string, ruleIds: string[]) =>
  client.delete(`api/policies/${keyId}`, { data: { ruleIds } });

export const editUsageAlarmRuleApi = (
  keyId: string,
  ruleId: string,
  max: number,
  isEnabled?: boolean,
) => client.patch(`/api/policies/${keyId}`, { ruleId, max, isEnabled });
