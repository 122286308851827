import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button, CancelButton } from '../button';
import { palette } from '../../lib/styles/palette';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 600px;
  padding: 69px 0;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: white;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin: 0 0 1rem 0;
    color: ${palette.logoColor};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .buttons {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;
const Description = styled.div`
  font-size: 13px;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 3rem;
  padding-right: 3rem;
  div {
    font-weight: bold;
    margin-top: 20px;
  }
`;
const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

type Props = {
  visible: boolean;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  subTitle?: string;
  subElement?: ReactNode;
  editModal?: boolean;
};

function ConfirmModal({
  visible,
  onCancel,
  onSubmit,
  title,
  subTitle,
  subElement,
  editModal = false,
}: Props) {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <div style={{ width: '30px', height: '30px' }} className="buttons">
          <CancelButton onCancel={onCancel} />
        </div>
        <h2>{title}</h2>
        <Description>
          <span>{subTitle}</span>
          <br />
          {subElement}
        </Description>
        <FooterContainer>
          <Button
            onClick={onCancel}
            inverted
            style={{ width: '160px', padding: '12px' }}
          >
            {editModal ? 'Cancel' : 'NO'}
          </Button>
          <Button
            onClick={onSubmit}
            style={{ width: '160px', padding: '12px', marginLeft: '50px' }}
          >
            {editModal ? 'Change' : 'YES'}
          </Button>
        </FooterContainer>
      </AskModalBlock>
    </Fullscreen>
  );
}

export default ConfirmModal;
