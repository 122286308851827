import React from 'react';
import styled from 'styled-components';
import { palette } from '../../../lib/styles/palette';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${palette.logoColor};
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.5px;
`;
const AgreeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AgreeParagraph = styled.p`
  color: #242424;
  font-size: 18px;
  letter-spacing: 0.36px;
`;
const CheckInput = styled.input`
  background-color: ${palette.logoColor};
  width: 25px;
  height: 25px;
  margin-left: 10px;
`;
const Description = styled.div`
  font-size: 14px;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const RegisterAgreeForm = React.forwardRef((props, ref) => (
  <Container>
    <Title>Register</Title>
    <AgreeContainer>
      <AgreeParagraph>I agree to the Terms of Service*</AgreeParagraph>
      <CheckInput type="checkbox" ref={ref} />
    </AgreeContainer>
    <Description>
      All User and Client of LOVO agrees to not use the voices from LOVO, or
      contents created with the voices from LOVO, for any use that can be deemed
      unethical, immoral, illegal, unlawful, or in any shape or form that might
      be deemed against the societal, cultural, and legal norms of the country
      you reside in and the contents will be available in. LOVO will not be held
      liable for the actions of the users or the clients in such fashion and
      manner.
    </Description>
  </Container>
));

export default RegisterAgreeForm;
