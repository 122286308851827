import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const TitleContainer = styled.div`
  padding-top: 66px;
  padding-left: 10%;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #000;
  text-align: left;
`;

type PageTitleProps = {
  title: string;
  style?: CSSProperties;
};

function PageTitle({ title, style }: PageTitleProps) {
  return (
    <TitleContainer className="test-pagetitle" style={style}>
      {title}
    </TitleContainer>
  );
}

export default PageTitle;
