import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';
import store from './modules/rootStore';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import HiCreatorMobile from './components/common/etc/HiCreatorMobile';

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_API_KEY));

// Initialize RecatGA
ReactGA.initialize('UA-175912114-1'); // lovo.ai Full URL View

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Elements stripe={stripePromise}>
          {isMobile ? <HiCreatorMobile visible /> : <App />}
        </Elements>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;
