import React from 'react';
import styled, { css } from 'styled-components';
import { palette } from '../../lib/styles/palette';

const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
`;
const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + .slider {
    background-color: ${palette.logoColor};
  }
  :focus + .slider {
    box-shadow: 0 0 1px ${palette.logoColor};
  }
  :checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }
`;
const Span = styled.span<{ disabled?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  :before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

type SwitchProps = {
  /** switch 가 on/off 될 지에 대한 여부 */
  checked: boolean;
  /** switch 가 on/off 되는 이벤트를 핸들링하는 함수 */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** disabled 될 지에 대한 여부 */
  disabled?: boolean;
};

export default function Switch({ checked, onChange, disabled }: SwitchProps) {
  return (
    <Container>
      <Input type="checkbox" checked={checked} onChange={onChange} />
      <Span className="slider" disabled={disabled} />
    </Container>
  );
}
