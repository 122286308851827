import { createAction, ActionType } from 'typesafe-actions';
import {
  CHECK_USER,
  CHECK_USER_SUCCESS,
  CHECK_USER_FAILURE,
  LOGOUT,
  CHANGE_FIELD,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_USER_INFO,
  CHANGE_USER_INFO_FAILURE,
  CHANGE_USER_INFO_SUCCESS,
  VERIFY_CHANGE_PASSWORD,
  VERIFY_CHANGE_PASSWORD_FAILURE,
  VERIFY_CHANGE_PASSWORD_SUCCESS,
  EXIT_USER_SUCCESS,
  EXIT_USER_FAILURE,
  EXIT_USER,
  SET_SESSION_EXPIRED,
  INITIALIZE_USER_STATUS,
} from './constants';
import { User, ApiUser } from './types';

/** logout */
export const logout = createAction(LOGOUT)();

/** check user */
export const checkUser = createAction(CHECK_USER)();
export const checkUserSuccess = createAction(
  CHECK_USER_SUCCESS,
  (user: User) => user,
)();
export const checkUserFailure = createAction(
  CHECK_USER_FAILURE,
  (error: string) => error,
)();

/** get current user */
export const getCurrentUser = createAction(GET_CURRENT_USER)();
export const getCurrentUserSuccess = createAction(
  GET_CURRENT_USER_SUCCESS,
  (apiUser: ApiUser) => apiUser,
)();
export const getCurrentUserFailure = createAction(
  GET_CURRENT_USER_FAILURE,
  (error: string) => error,
)();

/** change user info */
export const changeUserInfo = createAction(
  CHANGE_USER_INFO,
  (name: string, phone: string, job: string) => ({
    name,
    phone,
    job,
  }),
)();
export const changeUserInfoSuccess = createAction(
  CHANGE_USER_INFO_SUCCESS,
  (user: User) => user,
)();
export const changeUserInfoFailure = createAction(
  CHANGE_USER_INFO_FAILURE,
  (error: string) => error,
)();

/** verify change password */
export const verifyChangePassword = createAction(
  VERIFY_CHANGE_PASSWORD,
  (password: string) => ({
    password,
  }),
)();
export const verifyChangePasswordSuccess = createAction(
  VERIFY_CHANGE_PASSWORD_SUCCESS,
)();
export const verifyChangePasswordFailure = createAction(
  VERIFY_CHANGE_PASSWORD_FAILURE,
  (error: string) => error,
)();

/** change password */
export const changePassword = createAction(
  CHANGE_PASSWORD,
  (password: string) => ({
    password,
  }),
)();
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS)();
export const changePasswordFailure = createAction(
  CHANGE_PASSWORD_FAILURE,
  (error: string) => error,
)();

export const exitUser = createAction(EXIT_USER, (exitReason: string) => ({
  exitReason,
}))();
export const exitUserSuccess = createAction(EXIT_USER_SUCCESS)();
export const exitUserFailure = createAction(
  EXIT_USER_FAILURE,
  (error: string) => error,
)();

/** change field */
export const changeUserField = createAction(
  CHANGE_FIELD,
  (key: string, value: any) => ({ key, value }),
)();

export const initializeUserStatus = createAction(INITIALIZE_USER_STATUS)();
export const setSessionExpired = createAction(
  SET_SESSION_EXPIRED,
  (sessionExpired: boolean) => sessionExpired,
)();

const actions = {
  logout,
  checkUser,
  checkUserSuccess,
  checkUserFailure,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  changeUserInfo,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  changeUserField,
  verifyChangePassword,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  exitUser,
  exitUserSuccess,
  exitUserFailure,
  setSessionExpired,
  initializeUserStatus,
};

export type UserAction = ActionType<typeof actions>;
