import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeModalStatus, setModal } from '../modules/modal/actions';
import { RootState } from '../modules/rootReducer';
import { ModalTypes } from '../modules/modal/types';

export default function useModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state: RootState) => state.modal);

  const handleSetModal = useCallback(
    (type: ModalTypes, show: boolean, message?: string) =>
      dispatch(setModal(type, show, message)),
    [dispatch],
  );

  const handleInitializeModalStatus = useCallback(
    () => dispatch(initializeModalStatus()),
    [dispatch],
  );

  return {
    modal,
    handleSetModal,
    handleInitializeModalStatus,
  };
}
