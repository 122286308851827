import { all } from 'redux-saga/effects';
import {
  handleCheckUser,
  handleLogout,
  handleChangeUserInfo,
  handleGetCurrentUser,
  handleChangePassword,
  handleVerifyChangePassword,
  handleExitUser,
} from './user/sagas';
import {
  handleGenerateAPIKey,
  handleGetKeys,
  handleCancelSubscription,
  handleReactivateSubscription,
  handleGetKey,
  handleCreateWhiteList,
  handleDeleteWhiteList,
  handleCreateUsageAlarm,
  handleDeleteUsageAlarm,
  handleEditUsageAlarm,
} from './key/sagas';
import {
  handleGetPaymentMethods,
  handleAddPaymentMethod,
  handleUpdateUser,
  handleCreateSubscription,
  handleGetAllInvoices,
  handleGetPriceMetadata,
} from './billing/sagas';

export default function* rootSaga() {
  yield all([
    /** user */
    handleCheckUser(),
    handleLogout(),
    handleGetCurrentUser(),
    handleChangeUserInfo(),
    handleChangePassword(),
    handleVerifyChangePassword(),
    handleExitUser(),
    /** key */
    handleGetKeys(),
    handleGenerateAPIKey(),
    handleCancelSubscription(),
    handleReactivateSubscription(),
    handleGetKey(),
    handleCreateWhiteList(),
    handleDeleteWhiteList(),
    handleCreateUsageAlarm(),
    handleDeleteUsageAlarm(),
    handleEditUsageAlarm(),
    /** billing */
    handleGetPaymentMethods(),
    handleAddPaymentMethod(),
    handleUpdateUser(),
    handleCreateSubscription(),
    handleGetAllInvoices(),
    handleGetPriceMetadata(),
  ]);
}
