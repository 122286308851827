import { KeyDataState } from './types';

export const parseKeyData = (key: any): KeyDataState => {
  return {
    name: key.name,
    is_active: key.is_active,
    id: key._id,
    user_id: key.user,
    subscription_id: key.stripe.subscription_id,
    private: key.private,
    createdAt: key.createdAt,
    updatedAt: key.updatedAt,
    cancel_at_period_end: key.stripe.cancel_at_period_end,
    cancel_at: key.stripe.cancel_at,
    is_trial: key.stripe.is_trial,
    trial_ends_at: key.stripe.trial_ends_at,
    api_data: key.api_data,
    expired: key.expired,
    allowedOrigins: key.allowedOrigins,
    __v: key.__v,
    rules: key.rules,
  };
};
