import axios from 'axios';
import { initializeBillingStaus } from '../modules/billing/actions';
import { initializeKeyStaus } from '../modules/key/actions';
import { initializeModalStatus } from '../modules/modal/actions';
import store from '../modules/rootStore';
import {
  initializeUserStatus,
  setSessionExpired,
} from '../modules/user/actions';

export type Response = {
  success: boolean;
  data: any;
  message: string;
};

const apiServerUrl = `${process.env.REACT_APP_GATEWAY_SERVER_URL}`;

const gatewayClient = axios.create();
gatewayClient.defaults.baseURL = apiServerUrl;
gatewayClient.defaults.withCredentials = true;

gatewayClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (!(error.response.data.path === '/user/check')) {
        store.dispatch(initializeBillingStaus());
        store.dispatch(initializeKeyStaus());
        store.dispatch(initializeUserStatus());
        store.dispatch(initializeModalStatus());
        store.dispatch(setSessionExpired(true));
      }
    }
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return Promise.reject(error);
  },
);

export default gatewayClient;
