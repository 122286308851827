import { combineReducers } from 'redux';
import userReducer from './user/reducers';
import keyReducer from './key/reducer';
import modalReducer from './modal/reducers';
import billingReducer from './billing/reducers';

export const rootReducer = combineReducers({
  user: userReducer,
  key: keyReducer,
  modal: modalReducer,
  billing: billingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
