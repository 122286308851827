import axios from 'axios';
import { initializeBillingStaus } from '../modules/billing/actions';
import { initializeKeyStaus } from '../modules/key/actions';
import { initializeModalStatus } from '../modules/modal/actions';
import store from '../modules/rootStore';
import {
  initializeUserStatus,
  setSessionExpired,
} from '../modules/user/actions';

export type Response = {
  success: boolean;
  data: any;
  message: string;
  error: string;
};

const apiServerUrl = `${process.env.REACT_APP_API_SERVER_URL}`;

const apiClient = axios.create();
apiClient.defaults.baseURL = apiServerUrl;
apiClient.defaults.withCredentials = true;

apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(initializeKeyStaus());
      store.dispatch(initializeUserStatus());
      store.dispatch(initializeModalStatus());
      store.dispatch(initializeBillingStaus());
      store.dispatch(setSessionExpired(true));

      window.location.replace(process.env.REACT_APP_AUTH_CLIENT_URL as string);
    }
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return Promise.reject(error);
  },
);

export default apiClient;
