export const GET_PAYMENT_METHODS = 'billing/GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_SUCCESS =
  'billing/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAILURE =
  'billing/GET_PAYMENT_METHODS_FAILURE';

export const ADD_PAYMENT_METHOD = 'billing/ADD_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD_SUCCESS = 'billing/ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAILURE = 'billing/ADD_PAYMENT_METHOD_FAILURE';

export const CHANGE_FIELD = 'billing/CHANGE_FIELD';

export const CREATE_SUBSCRIPTION = 'billing/CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS =
  'billing/CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE =
  'billing/CREATE_SUBSCRIPTION_FAILURE';

export const GET_ALL_INVOICES = 'billing/GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = 'billing/GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_FAILURE = 'billing/GET_ALL_INVOICES_FAILURE';

export const INITIALIZE_BILLING_STATUS = 'billing/INITIALIZE_BILLING_STATUS';
export const GET_PRICE_METADATA = 'billing/GET_PRICE_METADATA';
export const GET_PRICE_METADATA_SUCCESS = 'billing/GET_PRICE_METADATA_SUCCESS';
export const GET_PRICE_METADATA_FAILURE = 'billing/GET_PRICE_METADATA_FAILURE';
