import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../modules/rootReducer';
import {
  checkUser,
  logout,
  changeUserInfo,
  changeUserField,
  getCurrentUser,
  verifyChangePassword,
  changePassword,
  exitUser,
  initializeUserStatus,
  setSessionExpired,
} from '../modules/user/actions';

function useUser() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const handleCheckUser = useCallback(() => dispatch(checkUser()), [dispatch]);

  const handleGetCurrentUser = useCallback(() => dispatch(getCurrentUser()), [
    dispatch,
  ]);

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const handleChangeUserInfo = useCallback(
    (name: string, phone: string, job: string) =>
      dispatch(changeUserInfo(name, phone, job)),
    [dispatch],
  );

  const handleVerifyChangePassword = useCallback(
    (password: string) => dispatch(verifyChangePassword(password)),
    [dispatch],
  );

  const handleChangePassword = useCallback(
    (password: string) => dispatch(changePassword(password)),
    [dispatch],
  );

  const handleExitUser = useCallback(
    (exitReason: string) => dispatch(exitUser(exitReason)),
    [dispatch],
  );

  const handleChangeUserField = useCallback(
    (key: string, value: any) => dispatch(changeUserField(key, value)),
    [dispatch],
  );

  const handleInitializeUserStatus = useCallback(
    () => dispatch(initializeUserStatus()),
    [dispatch],
  );

  const handleSetSessionExpired = useCallback(
    (sessionExpired: boolean) => dispatch(setSessionExpired(sessionExpired)),
    [dispatch],
  );

  return {
    user,
    handleCheckUser,
    handleGetCurrentUser,
    handleLogout,
    handleChangeUserInfo,
    handleVerifyChangePassword,
    handleChangePassword,
    handleChangeUserField,
    handleExitUser,
    handleInitializeUserStatus,
    handleSetSessionExpired,
  };
}

export default useUser;
