import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';
import dropdownBtn from '../../images/down_arrow.png';
import upArrowIcon from '../../images/up_arrow.png';
import { dropdownTheme } from '../../lib/styles/input/theme';

const Container = styled.div`
  width: 100%;
`;
const DropDownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.header};
`;
const DropDownTitle = styled.div`
  ${(props) => props.theme.title};
`;
const ToggleIcon = styled.img`
  width: 10px;
  height: 8px;
`;

interface DropDownProps {
  title: string;
  alternativeTitle?: string;
  theme?: 'sidebar' | 'disabled' | undefined;
  autoCollapse?: any;
  disabled?: boolean;
  className?: string;
  open?: boolean;
  children?: ReactNode;
}

const DropDown: React.FC<DropDownProps> = ({
  open,
  title,
  alternativeTitle,
  theme,
  autoCollapse,
  disabled,
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(!!open);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <DropDownHeader
        onClick={disabled ? undefined : toggleOpen}
        theme={
          disabled
            ? dropdownTheme.disabled
            : theme
            ? dropdownTheme[theme]
            : dropdownTheme.default
        }
        className={className}
      >
        <DropDownTitle
          theme={
            disabled
              ? dropdownTheme.disabled
              : theme
              ? dropdownTheme[theme]
              : dropdownTheme.default
          }
        >
          {alternativeTitle || title}
        </DropDownTitle>
        <ToggleIcon src={isOpen ? upArrowIcon : dropdownBtn} />
      </DropDownHeader>
      {isOpen ? (
        <div onClick={autoCollapse ? toggleOpen : undefined}>{children}</div>
      ) : undefined}
    </Container>
  );
};

export default DropDown;
