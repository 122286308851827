import { produce } from 'immer';
import { createReducer } from 'typesafe-actions';
import { setModal, ModalAction, initializeModalStatus } from './actions';
import { ModalState } from './types';

const initialState: ModalState = {
  DEFAULT: {
    show: false,
    message: '',
  },
  CONDITIONAL: {
    show: false,
    message: '',
  },
  REGISTER_AGREE: {
    show: false,
    message: '',
  },
};

const reducer = createReducer<ModalState, ModalAction>(initialState)
  .handleAction(setModal, (state, { payload }) =>
    produce(state, (draft) => {
      draft[payload.type] = { show: payload.show, message: payload.message };
    }),
  )
  .handleAction(initializeModalStatus, () => initialState);

export default reducer;
