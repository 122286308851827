import { createAction, ActionType } from 'typesafe-actions';
import { PaymentMethod } from '@stripe/stripe-js';
import { User } from '../user/types';
import { PriceMetadata } from './types';
import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_SUCCESS,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_FAILURE,
  ADD_PAYMENT_METHOD_SUCCESS,
  CHANGE_FIELD,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_FAILURE,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_FAILURE,
  GET_ALL_INVOICES_SUCCESS,
  INITIALIZE_BILLING_STATUS,
  GET_PRICE_METADATA,
  GET_PRICE_METADATA_SUCCESS,
  GET_PRICE_METADATA_FAILURE,
} from './constants';

/**
 * Action Types
 */
/** get PAYMENT_METHOD */
export const getPaymentMethods = createAction(GET_PAYMENT_METHODS)();
export const getPaymentMethodsSuccess = createAction(
  GET_PAYMENT_METHODS_SUCCESS,
  (paymentMethods: PaymentMethod[]) => paymentMethods,
)();
export const getPaymentMethodsFailure = createAction(
  GET_PAYMENT_METHODS_FAILURE,
  (error: string) => error,
)();
/** add payment method */
export const addPaymentMethod = createAction(
  ADD_PAYMENT_METHOD,
  (paymentMethod: PaymentMethod) => paymentMethod,
)();
export const addPaymentMethodSuccess = createAction(
  ADD_PAYMENT_METHOD_SUCCESS,
  (user: User) => user,
)();
export const addPaymentMethodFailure = createAction(
  ADD_PAYMENT_METHOD_FAILURE,
  (error: string) => error,
)();
/** change field */
export const changeBillField = createAction(
  CHANGE_FIELD,
  (key: string, value: any) => ({ key, value }),
)();

/** create subscriptioon */
export const createSubscription = createAction(
  CREATE_SUBSCRIPTION,
  (
    keyName: string,
    priceId: string,
    paymentMethodId?: string,
    action?: 'upgrade',
    subscriptionId?: string,
  ) => ({
    keyName,
    priceId,
    paymentMethodId,
    action,
    subscriptionId,
  }),
)();
export const createSubscriptionSuccess = createAction(
  CREATE_SUBSCRIPTION_SUCCESS,
  (user: User) => user,
)();
export const createSubscriptionFailure = createAction(
  CREATE_SUBSCRIPTION_FAILURE,
  (error: string) => error,
)();

export const getAllInvoices = createAction(GET_ALL_INVOICES)();
export const getAllInvoicesSuccess = createAction(
  GET_ALL_INVOICES_SUCCESS,
  (invoiceData: any) => invoiceData,
)();
export const getAllInvoicesFailure = createAction(
  GET_ALL_INVOICES_FAILURE,
  (error: string) => error,
)();

export const initializeBillingStaus = createAction(INITIALIZE_BILLING_STATUS)();

export const getPriceMetadata = createAction(GET_PRICE_METADATA)();
export const getPriceMetadataSuccess = createAction(
  GET_PRICE_METADATA_SUCCESS,
  (price: PriceMetadata) => price,
)();
export const getPriceMetadataFailure = createAction(
  GET_PRICE_METADATA_FAILURE,
  (error: string) => error,
)();

const actions = {
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  addPaymentMethod,
  addPaymentMethodSuccess,
  addPaymentMethodFailure,
  changeBillField,
  createSubscription,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  getAllInvoices,
  getAllInvoicesSuccess,
  getAllInvoicesFailure,
  initializeBillingStaus,
  getPriceMetadata,
  getPriceMetadataSuccess,
  getPriceMetadataFailure,
};
export type BillingActions = ActionType<typeof actions>;
