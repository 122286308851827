import React from 'react';
import styled from 'styled-components';
import { textBoxTheme } from '../../lib/styles/input/theme';

const Container = styled.input`
  width: 100%;
  font-size: 15px;

  border: none;
  outline: none;
  padding-bottom: 0.5rem;
  ${(props) => props.theme};
`;

export type TextBoxProps = {
  /** textbox 의 이름 */
  name?: string;
  /** placeholder */
  placeholder?: string;
  /** 기본값 */
  defaultValue?: string;
  /** input 의 type */
  type?: string;
  /** input 의 theme */
  theme?: 'outline' | undefined;
  /** style object */
  style?: any;
  /** class 이름 */
  className?: string;
};

const TextBox = React.forwardRef(
  (props: TextBoxProps, ref?: React.Ref<HTMLInputElement>) => (
    <Container
      className={props.className}
      name={props.name}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      type={props.type}
      theme={props.theme ? textBoxTheme[props.theme] : textBoxTheme.default}
      ref={ref}
      style={props.style}
    />
  ),
);

export default TextBox;
