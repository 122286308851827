import { css } from 'styled-components';
import { palette } from '../palette';

export const theme = {
  default: css`
    color: white;
    background-color: ${palette.logoColor};
    cursor: pointer;
    border: 2px solid ${palette.logoColor};
    &:hover {
      background-color: #5b47c7;
    }
  `,
  disabled: css`
    color: ${palette.grayColors[5]};
    background-color: ${palette.grayColors[3]};
    cursor: not-allowed;
  `,
  inverted: css`
    color: ${palette.logoColor};
    background-color: white;
    cursor: pointer;
    border: 2px solid ${palette.logoColor};
    transition: box-shadow 0.3s;
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }
  `,
};
