import { any } from 'cypress/types/bluebird';
import { createAction, ActionType } from 'typesafe-actions';
import {
  GET_KEYS,
  GET_KEYS_FAILURE,
  GET_KEYS_SUCCESS,
  GENERATE_KEY,
  GENERATE_KEY_FAILURE,
  GENERATE_KEY_SUCCESS,
  CHANGE_KEY_FIELD,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  INITIALIZE_KEY_STATUS,
  GET_KEY,
  GET_KEY_SUCCESS,
  GET_KEY_FAILURE,
  CREATE_WHITE_LIST,
  CREATE_WHITE_LIST_SUCCESS,
  CREATE_WHITE_LIST_FAILURE,
  DELETE_WHITE_LIST,
  DELETE_WHITE_LIST_SUCCESS,
  DELETE_WHITE_LIST_FAILURE,
  CREATE_USAGE_ALARM_RULE,
  CREATE_USAGE_ALARM_RULE_SUCCESS,
  CREATE_USAGE_ALARM_RULE_FAILURE,
  DELETE_USAGE_ALARM_RULE,
  DELETE_USAGE_ALARM_RULE_SUCCESS,
  DELETE_USAGE_ALARM_RULE_FAILURE,
  EDIT_USAGE_ALARM_RULE,
  EDIT_USAGE_ALARM_RULE_SUCCESS,
  EDIT_USAGE_ALARM_RULE_FAILURE,
} from './constants';
import { KeyDataState, UsageAlarmRule } from './types';

/** get keys */
export const getKeys = createAction(GET_KEYS)();
export const getKeysSuccess = createAction(
  GET_KEYS_SUCCESS,
  (keys: KeyDataState[]) => keys,
)();
export const getKeysFailure = createAction(
  GET_KEYS_FAILURE,
  (error: string) => error,
)();

/** generate key */
export const generateKey = createAction(GENERATE_KEY, (name: string) => ({
  name,
}))();
export const generateKeySuccess = createAction(GENERATE_KEY_SUCCESS)();
export const generateKeyFailure = createAction(
  GENERATE_KEY_FAILURE,
  (error: string) => error,
)();

/** change field */
export const changeKeyField = createAction(
  CHANGE_KEY_FIELD,
  (key: string, value: any) => ({ key, value }),
)();

/** cancel subscriptioon */
export const cancelSubscription = createAction(
  CANCEL_SUBSCRIPTION,
  (keyId: string) => keyId,
)();
export const cancelSubscriptionFailure = createAction(
  CANCEL_SUBSCRIPTION_FAILURE,
  (error: string) => error,
)();
export const cancelSubscriptionSuccess = createAction(
  CANCEL_SUBSCRIPTION_SUCCESS,
  (updatedKey: KeyDataState) => updatedKey,
)();

/** reactivate subscriptioon */
export const reactivateSubscription = createAction(
  REACTIVATE_SUBSCRIPTION,
  (keyId: string) => keyId,
)();
export const reactivateSubscriptionFailure = createAction(
  REACTIVATE_SUBSCRIPTION_FAILURE,
  (error: string) => error,
)();
export const reactivateSubscriptionSuccess = createAction(
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  (updatedKey: KeyDataState) => updatedKey,
)();

export const initializeKeyStaus = createAction(INITIALIZE_KEY_STATUS)();

// get key
export const getKey = createAction(GET_KEY, (keyId: string) => keyId)();

export const getKeySuccess = createAction(
  GET_KEY_SUCCESS,
  (key: KeyDataState) => key,
)();

export const getKeyFailure = createAction(
  GET_KEY_FAILURE,
  (error: string) => error,
)();

export const createWhiteList = createAction(
  CREATE_WHITE_LIST,
  (keyId: string, origins: string[]) => ({ keyId, origins }),
)();

export const createWhiteListSuccess = createAction(
  CREATE_WHITE_LIST_SUCCESS,
  (origins: string[]) => origins,
)();

export const createWhiteListFailure = createAction(
  CREATE_WHITE_LIST_FAILURE,
  (error: string) => error,
)();

// delete whitelist origin
export const deleteWhiteList = createAction(
  DELETE_WHITE_LIST,
  (keyId: string, origins: string[]) => ({ keyId, origins }),
)();

export const deleteWhiteListSuccess = createAction(
  DELETE_WHITE_LIST_SUCCESS,
  (origins: string[]) => origins,
)();

export const deleteWhiteListFailure = createAction(
  DELETE_WHITE_LIST_FAILURE,
  (error: string) => error,
)();

export const createUsageAlarmRule = createAction(
  CREATE_USAGE_ALARM_RULE,
  (
    keyId: string,
    type: 'minutes' | 'hours' | 'days' | 'months',
    duration: number,
    max: number,
  ) => ({ keyId, type, duration, max }),
)();

export const createUsageAlarmRuleSuccess = createAction(
  CREATE_USAGE_ALARM_RULE_SUCCESS,
  (rules: UsageAlarmRule[]) => rules,
)();

export const createUsageAlarmRuleFailure = createAction(
  CREATE_USAGE_ALARM_RULE_FAILURE,
  (error: string) => error,
)();

export const deleteUsageAlarmRule = createAction(
  DELETE_USAGE_ALARM_RULE,
  (keyId: string, ruleIds: string[]) => ({ keyId, ruleIds }),
)();

export const deleteUsageAlarmRuleSuccess = createAction(
  DELETE_USAGE_ALARM_RULE_SUCCESS,
  (ruleIds: string[]) => ruleIds,
)();

export const deleteUsageAlarmRuleFailure = createAction(
  DELETE_USAGE_ALARM_RULE_FAILURE,
  (error: string) => error,
)();

export const editUsageAlarmRule = createAction(
  EDIT_USAGE_ALARM_RULE,
  (keyId: string, ruleId: string, max: number, isEnabled?: boolean) => ({
    keyId,
    ruleId,
    max,
    isEnabled,
  }),
)();

export const editUsageAlarmRuleSuccess = createAction(
  EDIT_USAGE_ALARM_RULE_SUCCESS,
  (rules: UsageAlarmRule[]) => rules,
)();

export const editUsageAlarmRuleFailure = createAction(
  EDIT_USAGE_ALARM_RULE_FAILURE,
  (error: string) => error,
)();

const actions = {
  getKeys,
  getKeysSuccess,
  getKeysFailure,
  generateKey,
  generateKeyFailure,
  generateKeySuccess,
  changeKeyField,
  cancelSubscription,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  reactivateSubscription,
  reactivateSubscriptionFailure,
  reactivateSubscriptionSuccess,
  initializeKeyStaus,
  getKey,
  getKeySuccess,
  getKeyFailure,
  createWhiteList,
  createWhiteListSuccess,
  createWhiteListFailure,
  deleteWhiteList,
  deleteWhiteListSuccess,
  deleteWhiteListFailure,
  createUsageAlarmRule,
  createUsageAlarmRuleSuccess,
  createUsageAlarmRuleFailure,
  deleteUsageAlarmRule,
  deleteUsageAlarmRuleSuccess,
  deleteUsageAlarmRuleFailure,
  editUsageAlarmRule,
  editUsageAlarmRuleSuccess,
  editUsageAlarmRuleFailure,
};

export type KeyAction = ActionType<typeof actions>;
