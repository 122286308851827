import React from 'react';
import styled from 'styled-components';
import { Button } from '../button';
import { zIndexs } from '../../lib/styles/theme';

const Fullscreen = styled.div`
  position: fixed;
  z-index: ${zIndexs.modal};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
`;
const AskModalBlock = styled.div`
  max-width: 600px;
  width: 30%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.8rem;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
`;
const ButtonConatiner = styled.div`
  width: 35%;
  height: 54px;
  margin-top: 2rem;
`;
const Description = styled.div`
  font-size: 14px;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

type ModalProps = {
  /** Modal 을 보여줄지에 대한 여부 */
  visible: boolean;
  /** OK 버튼을 클릭하면 호출되는 함수 */
  onOK: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  /** Modal 메시지 내용 */
  message?: string;
  /** 닫기 버튼 라벨 */
  label?: string;
};

const Modal = ({ visible, onOK, message, label }: ModalProps) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock className="test-modal">
        <Description className="test-modal-message">{message}</Description>
        <ButtonConatiner>
          <Button onClick={onOK} className="test-modal-label">
            {label || 'OK'}
          </Button>
        </ButtonConatiner>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default Modal;
