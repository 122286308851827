import React from 'react';
import styled from 'styled-components';
import cancelIcon from '../../images/cancel.png';

const Container = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: white;
`;
const CancelIcon = styled.img`
  width: 100%;
  height: auto;
`;

type CancelButtonProps = {
  /** 클릭하면 호출되는 함수 */
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
};

function CancelButton({ onCancel }: CancelButtonProps) {
  return (
    <Container onClick={onCancel}>
      <CancelIcon src={cancelIcon} alt="cancel icon" />
    </Container>
  );
}

export default CancelButton;
